import React, { useEffect, useState } from 'react'
import { Collapse, Divider, Toast } from 'antd-mobile'
import { useSearchParams, useNavigate } from 'react-router-dom'
import moment from 'moment'
import { eventInfoApi } from '../../../service/order/eventOrderEventService'

const EventDetail = () => {
    const history = useNavigate();
    const [data, setData] = useState('')
    const [search, setSearch] = useSearchParams()  // eslint-disable-line no-unused-vars

    const eventInfoData = (value) => {
        eventInfoApi(value).then(res => {
            Toast.clear()
            if (res.code === 400) {
                Toast.show({
                    icon: 'fail',
                    content: '查询数据为空',
                })
                history('/event/order')
            } else if (res.code === 200) {
                // if (res.data.processChangePowerOrderInfo !== null) {
                setData(res.data)
                // } else {
                //     Toast.show({
                //         icon: 'fail',
                //         content: '事件信息为空！',
                //     })
                //     history('/event/order')
                // }
            } else {
                Toast.show({
                    icon: 'fail',
                    content: '未知错误',
                })
                history('/event/order')
            }

        }).catch(
            err => {
                Toast.show({
                    icon: 'fail',
                    content: '未知错误',
                })
                history('/event/order')
            }
        )
    }

    useEffect(() => {
        try {
            Toast.show({
                icon: 'loading',
                duration: 0,
                content: '加载中…',
            })
            eventInfoData({ "orderId": search.get('orderId'), "idStationOrder": search.get('idStationOrder') })
        } catch (e) {
            Toast.show({
                icon: 'fail',
                content: '未知错误',
            })
            history('/event/order')
        }
    }, [])//eslint-disable-line 

    const processChangePowerOrderInfo = data.processChangePowerOrderInfo
    const processExchange = data.processExchange
    return (
        <div className='topbackGroupClocl'>
            {
                data && <>
                    <Collapse defaultActiveKey={['1']}>
                        <Collapse.Panel key='1' title='订单信息'>
                            {
                                processChangePowerOrderInfo !== null ?
                                    (
                                        <div>
                                            <p>订单编号：{processChangePowerOrderInfo.orderNo}</p>
                                            <p>换电站名称：{processChangePowerOrderInfo.stationName}</p>
                                            <p>换电站版本：{processChangePowerOrderInfo.versionCode === '1' ? '一代站' : processChangePowerOrderInfo.versionCode === '2' ? '二代站' : ''}</p>
                                            <p>车牌号：{processChangePowerOrderInfo.plateNo}</p>
                                            <p>车辆Vin码：{processChangePowerOrderInfo.vin}</p>
                                        </div>
                                    ) : "订单数据获取为空"}
                        </Collapse.Panel>
                    </Collapse>
                    {
                        processChangePowerOrderInfo !== null ? (
                            <div>
                                <Divider>以下是换电云平台事件信息</Divider>
                                <Collapse defaultActiveKey={['1']}>
                                    {
                                        processChangePowerOrderInfo.processCarNoUploadResp !== null ?
                                            <Collapse.Panel key='2' title='站端上报车牌'>
                                                {
                                                    processChangePowerOrderInfo.processCarNoUploadResp !== null ? (
                                                        <div>
                                                            <p>车牌上报时间：{new Date(processChangePowerOrderInfo.processCarNoUploadResp.time).toLocaleString()}</p>
                                                        </div>
                                                    ) : ""
                                                }
                                            </Collapse.Panel> : ""
                                    }

                                    {
                                        processChangePowerOrderInfo.processNotifyVinResp !== null ?
                                            <Collapse.Panel key='3' title='云端下发Vin码'>
                                                {
                                                    processChangePowerOrderInfo.processNotifyVinResp !== null ? (
                                                        <div>
                                                            <p>是否可换电：{processChangePowerOrderInfo.processNotifyVinResp.ableToChange === true ? "是" : processChangePowerOrderInfo.processNotifyVinResp.ableToChange === false ? "否" : ""}</p>
                                                            <p>提示信息：{processChangePowerOrderInfo.processNotifyVinResp.msg}</p>
                                                            <p>下发Vin码时间：{moment(processChangePowerOrderInfo.processNotifyVinResp.time).format('YYYY-MM-DD HH-mm-ss')}</p>
                                                        </div>
                                                    ) : ""
                                                }
                                            </Collapse.Panel> : ""
                                    }

                                    {
                                        processChangePowerOrderInfo.processUploadBatteryCheckResp !== null ?
                                            <Collapse.Panel key='4' title='站端上报电池信息'>
                                                {processChangePowerOrderInfo.processUploadBatteryCheckResp !== null ?
                                                    (
                                                        <div>
                                                            <p>电池编码：{processChangePowerOrderInfo.processUploadBatteryCheckResp.batteryNo}</p>
                                                            <p>上报时间：{new Date(processChangePowerOrderInfo.processUploadBatteryCheckResp.time).toLocaleString()}</p>
                                                        </div>
                                                    ) : ""}
                                            </Collapse.Panel> : ""
                                    }

                                    {
                                        processChangePowerOrderInfo.processUploadBatteryCheckResultResp !== null ?
                                            <Collapse.Panel key='5' title='云端下发电池检测结果'>
                                                {processChangePowerOrderInfo.processUploadBatteryCheckResultResp !== null ? (
                                                    <div>
                                                        <p>电池编码：{processChangePowerOrderInfo.processUploadBatteryCheckResultResp.batteryNo}</p>
                                                        <p>检测结果：{processChangePowerOrderInfo.processUploadBatteryCheckResultResp.checkResult}</p>
                                                        <p>描述：{processChangePowerOrderInfo.processUploadBatteryCheckResultResp.message}</p>
                                                        <p>云端下发时间：{new Date(processChangePowerOrderInfo.processUploadBatteryCheckResultResp.time).toLocaleString()}</p>
                                                    </div>
                                                ) : ""}
                                            </Collapse.Panel> : ""
                                    }

                                    {
                                        processChangePowerOrderInfo.processInResp !== null ?
                                            <Collapse.Panel key='6' title='车辆进入站内'>
                                                {
                                                    processChangePowerOrderInfo.processInResp !== null ? (
                                                        <div>
                                                            <p>电池编码：{processChangePowerOrderInfo.processInResp.batteryCode}</p>
                                                            <p>电池包总里程：{processChangePowerOrderInfo.processInResp.totalOdometer}</p>
                                                            <p>电池包小计里程：{processChangePowerOrderInfo.processInResp.tripOdometer}</p>
                                                            <p>电池剩余电量：{processChangePowerOrderInfo.processInResp.soe}</p>
                                                            <p>电池SOC：{processChangePowerOrderInfo.processInResp.soc}</p>
                                                            <p>小计外接电量：{processChangePowerOrderInfo.processInResp.tripOutChargeEnergy}</p>
                                                            <p>电池单次输出电量：{processChangePowerOrderInfo.processInResp.singleOutputEnergy}</p>
                                                            <p>车辆总里程（KM）：{processChangePowerOrderInfo.processInResp.totalVehicleMileage}</p>
                                                            <p>进站时间：{new Date(processChangePowerOrderInfo.processInResp.time).toLocaleString()}</p>
                                                        </div>
                                                    ) : ""
                                                }
                                            </Collapse.Panel> : ""
                                    }

                                    {
                                        processChangePowerOrderInfo.processCarStopedResp !== null ?
                                            <Collapse.Panel key='7' title='车辆停稳'>
                                                {
                                                    processChangePowerOrderInfo.processCarStopedResp !== null ? (
                                                        <div>
                                                            <p>车牌号：{processChangePowerOrderInfo.processCarStopedResp.plateNo}</p>
                                                            <p>车辆Vin码：{processChangePowerOrderInfo.processCarStopedResp.vinCode}</p>
                                                            <p>电池编码：{processChangePowerOrderInfo.processCarStopedResp.batteryCode}</p>
                                                            <p>电池剩余电量：{processChangePowerOrderInfo.processCarStopedResp.soe}</p>
                                                            <p>电池SOC：{processChangePowerOrderInfo.processCarStopedResp.soc}</p>
                                                            <p>车辆总里程：{processChangePowerOrderInfo.processCarStopedResp.totalVehicleMileage}</p>
                                                            <p>停稳时间：{new Date(processChangePowerOrderInfo.processCarStopedResp.time).toLocaleString()}</p>
                                                        </div>
                                                    ) : ""
                                                }
                                            </Collapse.Panel> : ""
                                    }

                                    {
                                        processChangePowerOrderInfo.processVerifyVehicleResp !== null ?

                                            <Collapse.Panel key='8' title='云端确认换电车辆'>
                                                {
                                                    processChangePowerOrderInfo.processVerifyVehicleResp !== null ? (
                                                        <div>
                                                            <p>车牌号：{processChangePowerOrderInfo.processVerifyVehicleResp.plateNo}</p>
                                                            <p>车辆Vin码：{processChangePowerOrderInfo.processVerifyVehicleResp.vinCode}</p>
                                                            <p>电池编码：{processChangePowerOrderInfo.processVerifyVehicleResp.batteryCode}</p>
                                                            <p>交易流水号：{processChangePowerOrderInfo.processVerifyVehicleResp.stationOrderNo}</p>
                                                            <p>云端下发时间：{new Date(processChangePowerOrderInfo.processVerifyVehicleResp.time).toLocaleString()}</p>
                                                        </div>
                                                    ) : ""
                                                }
                                            </Collapse.Panel> : ""
                                    }

                                    {
                                        processChangePowerOrderInfo.processConfirmResultResp !== null ?
                                            <Collapse.Panel key='9' title='站端上报结果'>
                                                {
                                                    processChangePowerOrderInfo.processConfirmResultResp !== null ? (
                                                        <div>
                                                            <p>车牌号：{processChangePowerOrderInfo.processConfirmResultResp.plateNo}</p>
                                                            <p>车辆Vin码：{processChangePowerOrderInfo.processConfirmResultResp.vinCode}</p>
                                                            <p>确认结果：{processChangePowerOrderInfo.processConfirmResultResp.result}</p>
                                                            <p>上报时间：{new Date(processChangePowerOrderInfo.processConfirmResultResp.time).toLocaleString()}</p>
                                                        </div>
                                                    ) : ""
                                                }
                                            </Collapse.Panel> : ""
                                    }

                                    {
                                        processChangePowerOrderInfo.processUserConfirmsToChangeTheBatteryResp !== null ?
                                            <Collapse.Panel key='10' title='云端下控开始换电'>
                                                {
                                                    processChangePowerOrderInfo.processUserConfirmsToChangeTheBatteryResp !== null ? (
                                                        <div>
                                                            <p>车牌号：{processChangePowerOrderInfo.processUserConfirmsToChangeTheBatteryResp.plateNo}</p>
                                                            <p>车辆Vin码：{processChangePowerOrderInfo.processUserConfirmsToChangeTheBatteryResp.vinCode}</p>
                                                            <p>电池编码：{processChangePowerOrderInfo.processUserConfirmsToChangeTheBatteryResp.batteryCode}</p>
                                                            <p>换电请求：{processChangePowerOrderInfo.processUserConfirmsToChangeTheBatteryResp.swapRequest}</p>
                                                            <p>下发时间：{moment(processChangePowerOrderInfo.processUserConfirmsToChangeTheBatteryResp.time).format('YYYY-MM-DD HH-mm-ss')}</p>
                                                        </div>
                                                    ) : ""
                                                }
                                            </Collapse.Panel> : ""
                                    }

                                    {
                                        processChangePowerOrderInfo.processStartResp !== null ?
                                            <Collapse.Panel key='11' title='站端开始换电'>
                                                {
                                                    processChangePowerOrderInfo.processStartResp !== null ? (
                                                        <div>
                                                            <p>车牌号：{processChangePowerOrderInfo.processStartResp.plateNo}</p>
                                                            <p>车辆Vin码：{processChangePowerOrderInfo.processStartResp.vinCode}</p>
                                                            <p>电池编码：{processChangePowerOrderInfo.processStartResp.batteryCode}</p>
                                                            <p>电池剩余电量（KWH）：{processChangePowerOrderInfo.processStartResp.soe}</p>
                                                            <p>电池SOC（%）：{processChangePowerOrderInfo.processStartResp.soc}</p>
                                                            <p>车辆总里程（KM）：{processChangePowerOrderInfo.processStartResp.totalVehicleMileage}</p>
                                                            <p>电池小计里程（KM）：{processChangePowerOrderInfo.processStartResp.totalBatteryMileage}</p>
                                                            <p>上报时间：{new Date(processChangePowerOrderInfo.processStartResp.time).toLocaleString()}</p>
                                                        </div>
                                                    ) : ""
                                                }
                                            </Collapse.Panel> : ""
                                    }

                                    {
                                        processChangePowerOrderInfo.processTransportResp !== null ?
                                            <Collapse.Panel key='12' title='电池转运'>
                                                {
                                                    processChangePowerOrderInfo.processTransportResp !== null ? (
                                                        <div>
                                                            <p>拆下电池编码：{processChangePowerOrderInfo.processTransportResp.batteryCode}</p>
                                                            <p>拆卸次数：{processChangePowerOrderInfo.processTransportResp.dismountCount}</p>
                                                            <p>拆卸电池存入仓位：{processChangePowerOrderInfo.processTransportResp.storeChargerShelveNo}</p>
                                                            <p>装上电池取出仓位：{processChangePowerOrderInfo.processTransportResp.fetchChargerShelveNo}</p>
                                                            <p>上报时间：{new Date(processChangePowerOrderInfo.processTransportResp.time).toLocaleString()}</p>
                                                        </div>
                                                    ) : ""
                                                }
                                            </Collapse.Panel> : ""
                                    }

                                    {
                                        processChangePowerOrderInfo.processLockResp !== null ?
                                            <Collapse.Panel key='13' title='新电池锁紧'>
                                                {
                                                    processChangePowerOrderInfo.processLockResp !== null ? (
                                                        <div>
                                                            <p>装上电池编码：{processChangePowerOrderInfo.processLockResp.batteryCode}</p>
                                                            <p>装上电池SOC（%）：{processChangePowerOrderInfo.processLockResp.soc}</p>
                                                            <p>安装次数：{processChangePowerOrderInfo.processLockResp.mountCount}</p>
                                                            <p>装上电池总里程（KM）：{processChangePowerOrderInfo.processLockResp.totalBatteryMileage}</p>
                                                            <p>装上电池电量（KWH）：{processChangePowerOrderInfo.processLockResp.soc}</p>
                                                            <p>上报时间：{new Date(processChangePowerOrderInfo.processLockResp.time).toLocaleString()}</p>
                                                        </div>
                                                    ) : ""
                                                }
                                            </Collapse.Panel> : ""
                                    }

                                    {
                                        processChangePowerOrderInfo.processFinishResp !== null ?
                                            <Collapse.Panel key='14' title='换电完成'>
                                                {
                                                    processChangePowerOrderInfo.processFinishResp !== null ? (
                                                        <div>
                                                            <p>订单号：{processChangePowerOrderInfo.processFinishResp.orderId}</p>
                                                            <p>换电流水单号：{processChangePowerOrderInfo.processFinishResp.stationOrderNo}</p>
                                                            <p>换电站名称：{processChangePowerOrderInfo.processFinishResp.stationName}</p>
                                                            <p>车牌号：{processChangePowerOrderInfo.processFinishResp.plateNo}</p>
                                                            <p>车辆Vin码：{processChangePowerOrderInfo.processFinishResp.vinCode}</p>
                                                            <p>车辆总里程（KM）：{processChangePowerOrderInfo.processFinishResp.totalVehicleMileage}</p>
                                                            <p>换电开始时间：{processChangePowerOrderInfo.processFinishResp.startTime}</p>
                                                            <p>换电结束时间：{processChangePowerOrderInfo.processFinishResp.stopTime}</p>
                                                            <p>换下电池编码：{processChangePowerOrderInfo.processFinishResp.downBatteryCode}</p>
                                                            <p>换下电池小计外接能量（KWH）：{processChangePowerOrderInfo.processFinishResp.unloadBatteryOutChargeEnergy}</p>
                                                            <p>换下电池单次输出能量（KWH）：{processChangePowerOrderInfo.processFinishResp.unloadBatteryOutputEnergy}</p>
                                                            <p>换下电池soc(%)：{processChangePowerOrderInfo.processFinishResp.downUseableSoc}</p>
                                                            <p>换下电池包小计里程(km)：{processChangePowerOrderInfo.processFinishResp.batteryMileage}</p>
                                                            <p>换下电池包总计里程(km)：{processChangePowerOrderInfo.processFinishResp.downBatteryMileage}</p>
                                                            <p>装上电池编码：{processChangePowerOrderInfo.processFinishResp.upBatteryCode}</p>
                                                            <p>装上电池SOC(%)：{processChangePowerOrderInfo.processFinishResp.upUseableSoc}</p>
                                                            <p>拆下电池存入仓位：{processChangePowerOrderInfo.processFinishResp.pushChargerShelveNo}</p>
                                                            <p>装上电池取出仓位：{processChangePowerOrderInfo.processFinishResp.grabChargerShelveNo}</p>
                                                            <p>换电模式描述：{processChangePowerOrderInfo.processFinishResp.changeModeDesc}</p>
                                                            <p>换电结果：{processChangePowerOrderInfo.processFinishResp.changeResult}</p>
                                                            <p>换电结果说明：{processChangePowerOrderInfo.processFinishResp.changeResultDesc}</p>
                                                            <p>装上车电池剩余能量：{processChangePowerOrderInfo.processFinishResp.upBatteryPower}</p>
                                                            <p>装上电池总里程：{processChangePowerOrderInfo.processFinishResp.upBatteryMileage}</p>
                                                            <p>拆下电池剩余能量：{processChangePowerOrderInfo.processFinishResp.downBatteryPower}</p>
                                                            <p>上报时间：{new Date(processChangePowerOrderInfo.processFinishResp.time).toLocaleString()}</p>
                                                        </div>
                                                    ) : ""
                                                }
                                            </Collapse.Panel> : ""
                                    }

                                    {
                                        processChangePowerOrderInfo.processLeaveResp !== null ?
                                            <Collapse.Panel key='15' title='车辆驱离换电站'>
                                                {
                                                    processChangePowerOrderInfo.processLeaveResp !== null ? (
                                                        <div>
                                                            <p>车牌号：{processChangePowerOrderInfo.processLeaveResp.plateNo}</p>
                                                            <p>车辆Vin码：{processChangePowerOrderInfo.processLeaveResp.vinCode}</p>
                                                            <p>上报时间：{new Date(processChangePowerOrderInfo.processLeaveResp.time).toLocaleString()}</p>
                                                        </div>
                                                    ) : ""
                                                }
                                            </Collapse.Panel> : ""
                                    }

                                </Collapse>
                            </div>
                        ) : ""
                    }
                    {processExchange === null ? "" :
                        <div>
                            <Divider>以下是阿里云事件信息</Divider>
                            {
                                processExchange.carEnterPowerStation || processExchange.bizCarEnterStation || processExchange.carStopped || processExchange.bizCarStopped ?
                                    <Collapse defaultActiveKey={['1']}>
                                        {
                                            processExchange.carEnterPowerStation || processExchange.bizCarEnterStation ?
                                                <Collapse.Panel key='16' title='车辆进站'>
                                                    {
                                                        processExchange.carEnterPowerStation ? processExchange.carEnterPowerStation.map((item, index) => {
                                                            return (
                                                                <div key={index}>
                                                                    <pre>
                                                                        {JSON.stringify(item.header, null, 2)}
                                                                    </pre>
                                                                    <Divider />
                                                                    <pre>
                                                                        {JSON.stringify(item.body, null, 2)}
                                                                    </pre>
                                                                </div>
                                                            )
                                                        }) : processExchange.bizCarEnterStation !== undefined ?
                                                            processExchange.bizCarEnterStation.map(item => {
                                                                return (
                                                                    <div>
                                                                        <pre>
                                                                            {JSON.stringify(item.header, null, 2)}
                                                                        </pre>
                                                                        <Divider />
                                                                        <pre>
                                                                            {JSON.stringify(item.body, null, 2)}
                                                                        </pre>
                                                                    </div>
                                                                )
                                                            }) : ""
                                                    }
                                                </Collapse.Panel> : ""
                                        }

                                        {
                                            processExchange.carStopped || processExchange.bizCarStopped ?
                                                <Collapse.Panel key='17' title='车辆停稳'>
                                                    {
                                                        processExchange.carStopped ? processExchange.carStopped.map((item, index) => {
                                                            return (
                                                                <div key={index}>
                                                                    <pre>
                                                                        {JSON.stringify(item.header, null, 2)}
                                                                    </pre>
                                                                    <Divider />
                                                                    <pre>
                                                                        {JSON.stringify(item.body, null, 2)}
                                                                    </pre>
                                                                </div>
                                                            )
                                                        }) : processExchange.bizCarStopped !== undefined ? processExchange.bizCarStopped.map((item, index) => {
                                                            return (
                                                                <div key={index}>
                                                                    <pre>
                                                                        {JSON.stringify(item.header, null, 2)}
                                                                    </pre>
                                                                    <Divider />
                                                                    <pre>
                                                                        {JSON.stringify(item.body, null, 2)}
                                                                    </pre>
                                                                </div>
                                                            )
                                                        }) : ""
                                                    }
                                                </Collapse.Panel> : ""
                                        }
                                    </Collapse> : ""
                            }

                            {
                                processExchange.cmdCarReadyStatusRequest || processExchange.bizCarReadyResult ?
                                    <Collapse defaultActiveKey={['1']}>
                                        {processExchange.cmdCarReadyStatusRequest === undefined ? "" : (
                                            <div>
                                                {
                                                    processExchange.cmdCarReadyStatusRequest === undefined ? "" :
                                                        <Collapse.Panel key='18' title='云端检测车辆'>
                                                            {
                                                                processExchange.cmdCarReadyStatusRequest.map((item, index) => {
                                                                    return (
                                                                        <div key={index}>
                                                                            <pre>
                                                                                {JSON.stringify(item.header, null, 2)}
                                                                            </pre>
                                                                            <Divider />
                                                                            <pre>
                                                                                {JSON.stringify(item.body, null, 2)}
                                                                            </pre>
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                        </Collapse.Panel>
                                                }

                                                {
                                                    processExchange.bizCarReadyResult === undefined ? "" :
                                                        <Collapse.Panel key='19' title='站端上报确认结果'>
                                                            {
                                                                processExchange.bizCarReadyResult === undefined ? "" : processExchange.bizCarReadyResult.map((item, index) => {
                                                                    return (
                                                                        <div key={index}>
                                                                            <pre>
                                                                                {JSON.stringify(item.header, null, 2)}
                                                                            </pre>
                                                                            <Divider />
                                                                            <pre>
                                                                                {JSON.stringify(item.body, null, 2)}
                                                                            </pre>
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                        </Collapse.Panel>
                                                }
                                            </div>)}
                                    </Collapse> : ""
                            }

                            {
                                processExchange.beginToChangePower || processExchange.bizStartSwap || processExchange.changePowerDone || processExchange.bizSwapDone ?
                                    <Collapse defaultActiveKey={['1']}>
                                        {
                                            processExchange.beginToChangePower || processExchange.bizStartSwap ?
                                                <Collapse.Panel key='20' title='开始换电'>
                                                    {
                                                        processExchange.beginToChangePower ? processExchange.beginToChangePower.map((item, index) => {
                                                            return (
                                                                <div key={index}>
                                                                    <pre>
                                                                        {JSON.stringify(item.header, null, 2)}
                                                                    </pre>
                                                                    <Divider />
                                                                    <pre>
                                                                        {JSON.stringify(item.body, null, 2)}
                                                                    </pre>
                                                                </div>
                                                            )
                                                        }) : processExchange.bizStartSwap !== undefined ? processExchange.bizStartSwap.map((item, index) => {
                                                            return (
                                                                <div key={index}>
                                                                    <pre>
                                                                        {JSON.stringify(item.header, null, 2)}
                                                                    </pre>
                                                                    <Divider />
                                                                    <pre>
                                                                        {JSON.stringify(item.body, null, 2)}
                                                                    </pre>
                                                                </div>
                                                            )
                                                        }) : ""
                                                    }
                                                </Collapse.Panel> : ""
                                        }

                                        {
                                            processExchange.changePowerDone || processExchange.bizSwapDone ?
                                                <Collapse.Panel key='21' title='换电完成'>
                                                    {
                                                        processExchange.changePowerDone ? processExchange.changePowerDone.map((item, index) => {
                                                            return (
                                                                <div key={index}>
                                                                    <pre>
                                                                        {JSON.stringify(item.header, null, 2)}
                                                                    </pre>
                                                                    <Divider />
                                                                    <pre>
                                                                        {JSON.stringify(item.body, null, 2)}
                                                                    </pre>
                                                                </div>
                                                            )
                                                        }) : processExchange.bizSwapDone !== undefined ? processExchange.bizSwapDone.map((item, index) => {
                                                            return (
                                                                <div key={index}>
                                                                    <pre>
                                                                        {JSON.stringify(item.header, null, 2)}
                                                                    </pre>
                                                                    <Divider />
                                                                    <pre>
                                                                        {JSON.stringify(item.body, null, 2)}
                                                                    </pre>
                                                                </div>
                                                            )
                                                        }) : ""
                                                    }
                                                </Collapse.Panel> : ""
                                        }
                                    </Collapse > : ""
                            }
                        </div>
                    }
                    {data.batteryChargeAssetsInfo === undefined && data.batteryChargeProdInfo === undefined ? "" :
                        (data.batteryChargeAssetsInfo !== undefined && data.batteryChargeAssetsInfo.length === 0) || (data.batteryChargeProdInfo !== undefined && data.batteryChargeProdInfo.length === 0) ? "" : (
                            <Collapse >
                                <Collapse.Panel key='22' title='充电完成'>
                                    {data.batteryChargeAssetsInfo !== undefined ?
                                        <pre >{JSON.stringify(data.batteryChargeAssetsInfo, null, 2)}</pre> :
                                        <pre >{JSON.stringify(data.batteryChargeProdInfo, null, 2)}</pre>}
                                </Collapse.Panel>
                            </Collapse >)
                    }
                </>}</div>
    )
}
export default EventDetail