import React from 'react'
import { Form, Button, Space, Toast, TextArea, Input } from 'antd-mobile'
import { useSearchParams } from 'react-router-dom'
import { FeedbackStaionVehicleApi } from '../../../service/feedback'


const StationModal = () => {
    const [form] = Form.useForm()
    const [search, setSearch] = useSearchParams()  // eslint-disable-line no-unused-vars


    const onFinish = (value) => {
        Toast.show({
            icon: 'loading',
            duration: 0,
            content: '加载中…',
        })

        FeedbackStaionVehicleApi({ "stationName": search.get('stationName'), "plateNo": value.plateNo, "content": value.content }).then(res => {
            Toast.clear()
            if (res.code === 400) {
                Toast.show({
                    icon: 'fail',
                    content: res.msg,
                })
            } else if (res.code === 200) {
                Toast.show({
                    icon: 'success',
                    content: '添加成功！',
                })
                Toast.clear()
                window.history.back();
            } else {
                Toast.show({
                    icon: 'fail',
                    content: '未知错误',
                })
            }

        }).catch(
            err => {
                Toast.show({
                    icon: 'fail',
                    content: '未知错误',
                })
            }
        )
    }

    return (
        <div className='topbackGroupClocl'>
            <h3 style={{ marginLeft: 10 }}>车辆反馈</h3>
            <Form
                form={form}
                layout='vertical'
                onFinish={onFinish}
                footer={
                    <>
                        <Space justify='center' className='eventBtnWrap' >
                            <Button block type='submit' size="large" color='primary' >
                                提交
                            </Button>
                            <Button block size="large" type='reset' >
                                重置
                            </Button>
                        </Space>
                    </>
                }

            >
                <Form.Item label='换电站名称' name='stationName' >
                    <Input placeholder={search.get('stationName')} value={search.get('stationName')} readOnly />
                </Form.Item>

                <Form.Item label='车牌号' name='plateNo' rules={[{ required: true, message: '内容不能为空' }, { max: 50, message: '请输入内容' }]}>
                    <Input placeholder='请输入车牌号' />
                </Form.Item>

                <Form.Item label='备注' name='content' rules={[{ max: 1000, message: '请输入内容' }]}>
                    <TextArea showCount maxLength={1000} />
                </Form.Item>
            </Form>
        </div>
    )
}
export default StationModal
