import React from 'react'
import { FloatingBubble } from 'antd-mobile'
import { useNavigate } from 'react-router-dom'
import { Customer } from '@icon-park/react'
import './index.scss'

const FloatingBubbleHome = () => {
    const history = useNavigate();

    const onClick = () => {
        history('/feedback')
    }
    return (
        <div
            style={{
                textAlign: 'center',
                padding: '50vh 32px 0',
            }}
        >
            <FloatingBubble
                axis='xy'
                magnetic='x'
                style={{
                    '--initial-position-bottom': '300px',
                    '--initial-position-right': '0px',
                }}
                onClick={onClick}
            >
                <Customer theme="filled" size="42" fill="#4a90e2" strokeWidth={1} strokeLinejoin="miter" />
            </FloatingBubble>
        </div>
    )
}

export default FloatingBubbleHome