import React from 'react'
import { Collapse, Toast, Divider } from 'antd-mobile'
import { useSearchParams, useNavigate } from 'react-router-dom'
import { useState, useEffect } from 'react'
import { getRentLogApi } from '../../../service/order'


const RentRecordDetil = () => {
    const [data, setData] = useState()
    const history = useNavigate();
    const [search, setSearch] = useSearchParams() // eslint-disable-line no-unused-vars

    const rentRecordData = (value) => {

        getRentLogApi(value).then(res => {
            Toast.clear()
            if (res.code === 400) {
                Toast.show({
                    icon: 'fail',
                    content: res.msg,
                })
                history('/order/rent')
            } else if (res.code === 200) {
                setData(res.data)
            } else {
                Toast.show({
                    icon: 'fail',
                    content: '未知错误',
                })
                history('/order/rent')
            }
        }).catch(
            err => {
                Toast.show({
                    icon: 'fail',
                    content: '未知错误',
                })
                history('/order/rent')
            }
        )
    }
    useEffect(() => {
        try {
            Toast.show({
                icon: 'loading',
                duration: 0,
                content: '加载中…',
            })
            rentRecordData({ 'vinCode': search.get('vinCode') })
        } catch (e) {
            Toast.show({
                icon: 'fail',
                content: '未知错误',
            })
            history('/order/rent')
        }
    }, [])//eslint-disable-line 



    return (
        <div className='topbackGroupClocl'>{
            data &&
            <Collapse defaultActiveKey={['1']}>
                <Collapse.Panel key='1' title='基础信息'>
                    <p>车辆Vin码：{data.vinCode}</p>
                    <p>最新结算日：{data.settlementDay}</p>
                </Collapse.Panel>
                {
                    (data.rentDateLogList && data.rentDateLogList.length > 0) &&
                    <Collapse.Panel key='2' title='月度缴纳日更改记录'>

                        {data.rentDateLogList.map((item, index) => {
                            return (
                                <div key={index}>
                                    <p>更改后月度缴纳日：{item.rentDay}</p>
                                    <p>修改人：{item.userName}</p>
                                    <p>修改时间：{item.upTime}</p>
                                    {data.rentDateLogList.length - 1 === index ? "" : <Divider />}
                                </div>
                            )
                        })}
                    </Collapse.Panel>
                }
                {
                    (data.rentDueLiquidatedDamages.rentWaitPayData.length > 0) &&
                    <Collapse.Panel key='3' title='未缴纳租金账单'>

                        {data.rentDueLiquidatedDamages.rentWaitPayData.map((item, index) => {
                            return (
                                <div key={index}>
                                    <p>订单编号：{item.orderNo}</p>
                                    <p>所属月份：{item.belongMonth}</p>
                                    <p>下单时间：{item.settlementDay}</p>
                                    <p>车辆类型：{item.tagEnd}</p>
                                    <p>状态：{item.status}</p>
                                    {data.rentDueLiquidatedDamages.rentWaitPayData.length - 1 === index ? "" : <Divider />}
                                </div>
                            )
                        })}
                    </Collapse.Panel>
                }
                {
                    (data.rentDueLiquidatedDamages.liquidatedDamagesWaitPayData.length > 0) &&
                    <Collapse.Panel key='4' title='未缴纳违约金账单'>

                        {data.rentDueLiquidatedDamages.liquidatedDamagesWaitPayData.map((item, index) => {
                            return (
                                <div key={index}>
                                    <p>订单编号：{item.rentOrderNo}</p>
                                    <p>所属月份：{item.settlementMonth}</p>
                                    <p>状态：{item.liquidatedDamagesStatus}</p>
                                    {data.rentDueLiquidatedDamages.liquidatedDamagesWaitPayData.length - 1 === index ? "" : <Divider />}
                                </div>
                            )
                        })}
                    </Collapse.Panel>
                }
            </Collapse>
        }
        </div>
    )
}
export default RentRecordDetil