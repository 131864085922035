import React, { useEffect, useState } from 'react'
import { Collapse, Divider, Toast } from 'antd-mobile'
import { useSearchParams, useNavigate } from 'react-router-dom'
import { eventDeviceInfoApi } from '../../../service/station/eventDeviceService'

const EventDeviceDetail = () => {
    const history = useNavigate();
    const [data, setData] = useState([])
    const [search, setSearch] = useSearchParams()  // eslint-disable-line no-unused-vars

    const eventDeviceInfoData = (value) => {
        eventDeviceInfoApi(value).then(res => {
            Toast.clear()
            if (res.code === 400) {
                Toast.show({
                    icon: 'fail',
                    content: res.msg,
                })
                history('/event/device')
            } else if (res.code === 200) {
                if (res.data.length > 0) {
                    setData(res.data)
                } else {
                    Toast.show({
                        icon: 'fail',
                        content: '查询数据为空',
                    })
                    history('/event/device')
                }

            } else {
                Toast.show({
                    icon: 'fail',
                    content: '未知错误',
                })
                history('/event/device')
            }

        }).catch(
            err => {
                Toast.show({
                    icon: 'fail',
                    content: '未知异常',
                })
                history('/event/device')
            }
        )
    }

    useEffect(() => {
        Toast.show({
            icon: 'loading',
            duration: 0,
            content: '加载中…',
        })
        eventDeviceInfoData({ "stationName": search.get('stationName'), "commandTypeName": search.get('commandTypeName') })
        // eslint-disable-next-line 
    }, [])

    return (
        <div className='topbackGroupClocl'>
            {
                data && <>
                    <h3 style={{ marginLeft: 10 }}>二代站设备下控记录</h3>
                    <Collapse defaultActiveKey={['1']}>
                        {
                            data.map((item, index) => {
                                return (
                                    <Collapse.Panel key={index + 1} title={item.commandReq.header.timestamp}>
                                        <p>请求信息：</p>
                                        <p>请求头：</p>
                                        <pre>
                                            {JSON.stringify(item.commandReq.header, null, 2)}
                                        </pre>
                                        <Divider />
                                        <p>请求body：</p>
                                        <pre>
                                            {JSON.stringify(item.commandReq.body, null, 2)}
                                        </pre>
                                        <Divider
                                            style={{
                                                color: '#1677ff',
                                                borderColor: '#1677ff',
                                                borderStyle: 'dashed',
                                            }}
                                        />
                                        <p>响应信息：</p>
                                        <p>响应头：</p>
                                        <pre>
                                            {JSON.stringify(item.commandResp.header, null, 2)}
                                        </pre>
                                        <Divider />
                                        <p>响应body：</p>
                                        <pre>
                                            {JSON.stringify(item.commandResp.body, null, 2)}
                                        </pre>
                                    </Collapse.Panel>
                                )
                            })
                        }
                    </Collapse>
                </>}
        </div>
    )
}
export default EventDeviceDetail