import React from 'react'
import { Form, Input, Button, Space, Toast, Switch } from 'antd-mobile'
import { useNavigate } from 'react-router-dom'
import DateTimePicker from '../../../components/common/DateTimePicker'
import SlsStorePickSelect from '../../../components/common/SlsStorePickSelect'

import './index.scss'

const EventSls = function () {
    const [form] = Form.useForm()
    const history = useNavigate();

    const onFinish = (value) => {

        Toast.show({
            icon: 'loading',
            duration: 0,
            content: '加载中…',
        })
        history(`/event/logsls/detail?logStore=${value.logStore}&isTencent=${value.isTencent}&content=${value.content}&startTime=${new Date(value.startTime).getTime() / 1000}&endTime=${new Date(value.endTime).getTime() / 1000}`)
        Toast.clear()
    }

    return (
        <div className='topbackGroupClocl'>
            <h3 style={{ marginLeft: 10 }}>日志实时查询</h3>
            <Form
                form={form}
                layout='vertical'
                initialValues={{ isTencent: false }}
                onFinish={onFinish}
                footer={
                    <>
                        <Space justify='center' className='controltBtnWrap' >
                            <Button block type='submit' size="large" color='primary' className='butSub'>
                                提交
                            </Button>
                            <Button block size="large" type='reset' className='butRest'>
                                重置
                            </Button>
                        </Space>
                    </>
                }

            >
                <Form.Item label='应用名称' name='logStore' rules={[{ required: true, message: '应用名称不能为空' }, { max: 50, message: '请输入正确的应用名称' }]}>
                    <SlsStorePickSelect name='请输入应用名称' />
                </Form.Item>
                <Form.Item label='腾讯云' name='isTencent'>
                    <Switch defaultChecked={false} />
                </Form.Item>
                <Form.Item label='关键字' name='content' rules={[{ required: true, message: '关键字不能为空' }, { max: 200, message: '关键字过长' }]}>
                    <Input placeholder='请输入关键字' />
                </Form.Item>
                <Form.Item label='开始时间' name='startTime' rules={[{ required: true, message: '开始时间不能为空' }]}>
                    <DateTimePicker name='请输入开始时间' />
                </Form.Item>
                <Form.Item label='结束时间' name='endTime' rules={[{ required: true, message: '结束时间不能为空' }]}>
                    <DateTimePicker name='请输入结束时间' />
                </Form.Item>
            </Form>
        </div>
    )
}
export default EventSls