import React from 'react'
import { Form, Button, Space, Toast, Selector, TextArea } from 'antd-mobile'
import { FeedbackInfoApi } from '../../service/feedback'

import './index.scss'
import ImageUp from '../../components/common/ImageUp'

const Feedback = function () {
    const [form] = Form.useForm()
    let urlPath = '';

    const onFinish = (value) => {
        Toast.show({
            icon: 'loading',
            duration: 0,
            content: '加载中…',
        })
        if (value.url?.length > 0) {
            for (let i = 0; i < value.url.length; i++) {
                const urlObj = value.url[i];
                urlPath += urlObj.url + (value.url.length - 1 === i ? "" : ",");
            }
        }

        FeedbackInfoApi({ "content": value.content, "type": value.feedbackType[0], "url": urlPath }).then(res => {
            Toast.clear()
            if (res.code === 400) {
                Toast.show({
                    icon: 'fail',
                    content: '添加失败！',
                })
            } else if (res.code === 200) {
                Toast.show({
                    icon: 'success',
                    content: '添加成功！',
                })
                window.history.back();
            } else {
                Toast.show({
                    icon: 'fail',
                    content: '未知错误',
                })
            }

        }).catch(
            err => {
                Toast.show({
                    icon: 'fail',
                    content: '未知错误',
                })
            }
        )
    }

    return (
        <div className='topbackGroupClocl'>
            <h3 style={{ marginLeft: 10 }}>我的反馈</h3>
            <Form
                form={form}
                layout='vertical'
                onFinish={onFinish}
                footer={
                    <>
                        <Space justify='center' className='eventBtnWrap' >
                            <Button block type='submit' size="large" color='primary' >
                                提交
                            </Button>
                            <Button block size="large" type='reset' >
                                重置
                            </Button>
                        </Space>
                    </>
                }

            >
                <Form.Item label='反馈类型：' name="feedbackType" rules={[{ required: true, message: '请选择反馈类型' }]}>
                    <Selector className='selector'
                        options={[
                            {
                                label: '换电站',
                                value: '1',
                            },
                            {
                                label: '订单',
                                value: '2',
                            },
                            {
                                label: '易指尖',
                                value: '3',
                            },
                            {
                                label: '易易App',
                                value: '4',
                            },
                            {
                                label: 'SAAS',
                                value: '5',
                            },
                            {
                                label: '排队',
                                value: '6',
                            },
                        ]}
                    />
                </Form.Item >

                <Form.Item label='内容' name='content' rules={[{ required: true, message: '内容不能为空' }, { max: 1000, message: '请输入内容' }]}>
                    <TextArea showCount maxLength={1000} />
                </Form.Item>
                <Form.Item name='url'>
                    <ImageUp />
                </Form.Item>
            </Form>
        </div>
    )
}
export default Feedback