import { post,postFile } from '../../utils/request'


export function FeedbackInfoApi(data) {
    return post('/feedback/add', data)
}
export function vehicleNotLineApi(data) {
    return post('/feedback/add/line', data)
}

export function FeedbackStaionVehicleApi(data) {
    return post('/feedback/station/vehicle', data)
}

export function iMAgeUploaderApi(data){
    return postFile('/oss/invoice/upload',data)
}