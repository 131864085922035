import React from 'react'
import { Form, Input, Button, Space, Toast } from 'antd-mobile'
import { useNavigate } from 'react-router-dom'

import DateTimePicker from '../../../components/common/DateTimePicker'



// import './index.scss'

const CasualClock = function () {
    const [form] = Form.useForm()
    const history = useNavigate();

    const onFinish = (value) => {

        Toast.show({
            icon: 'loading',
            duration: 0,
            content: '加载中…',
        })
        history(`/casual/clock/detail?&account=${value.account}&startTime=${new Date(value.startTime).getTime()}&endTime=${new Date(value.endTime).getTime()}`)
        Toast.clear()
    }

    return (
        <div className='topbackGroupClocl'>
            <h3 style={{ marginLeft: 10 }}>临时加班成队性校验</h3>
            <Form
                form={form}
                layout='vertical'
                initialValues={{ isTencent: false }}
                onFinish={onFinish}
                footer={
                    <>
                        <Space justify='center' className='controltBtnWrap' >
                            <Button block type='submit' size="large" color='primary' className='butSub'>
                                提交
                            </Button>
                            <Button block size="large" type='reset' className='butRest'>
                                重置
                            </Button>
                        </Space>
                    </>
                }

            >
                <Form.Item label='账号' name='account' rules={[{ max: 200, message: '关键字过长' }]}>
                    <Input placeholder='请输入账号' />
                </Form.Item>
                <Form.Item label='班次开始' name='startTime' rules={[{ required: true, message: '开始时间不能为空' }]}>
                    <DateTimePicker name='请输入班次开始时间' />
                </Form.Item>
                <Form.Item label='班次结束' name='endTime' rules={[{ required: true, message: '结束时间不能为空' }]}>
                    <DateTimePicker name='请输入班次结束时间' />
                </Form.Item>
            </Form>
        </div>
    )
}
export default CasualClock