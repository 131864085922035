import { get } from '../../utils/request'


export function getStoreApi() {
    return get('/log/store/list')
}

export function orderInfoApi(data) {
    return get('/order/orderInfo',data)
}

export function getRentLogApi(data) {
    return get('/rent/log', data)
}
