import React, { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { eventLogSlsApi } from '../../../service/tool/eventSlsService'
import { Toast, Divider } from 'antd-mobile'
import './index.scss'

const EventSlsDetail = () => {
    const [data, setData] = useState([])
    const history = useNavigate();
    const [search, setSearch] = useSearchParams()  // eslint-disable-line no-unused-vars

    const EventSlsDetailData = (value) => {
        eventLogSlsApi(value).then(res => {
            Toast.clear()
            if (res.code === 400) {
                Toast.show({
                    icon: 'fail',
                    content: '查询无结果',
                })
                history('/event/logsls')
            } else if (res.code === 200) {
                if (res.data.length > 0) {
                    setData(res.data)
                } else {
                    Toast.show({
                        icon: 'fail',
                        content: '查询为空',
                    })
                    history('/event/logsls')
                }
            } else {
                Toast.show({
                    icon: 'fail',
                    content: '未知错误',
                })
                history('/event/logsls')
            }

        }).catch(
            err => {
                Toast.show({
                    icon: 'fail',
                    content: '未知错误',
                })
                history('/event/logsls')
            }
        )
    }

    useEffect(() => {
        try {
            Toast.show({
                icon: 'loading',
                duration: 0,
                content: '加载中…',
            })
            EventSlsDetailData({ "logStore": search.get('logStore'), "isTencent": search.get('isTencent'), "content": search.get('content'), "startTime": search.get('startTime'), "endTime": search.get('endTime') })
        } catch (e) {
            Toast.show({
                icon: 'fail',
                content: '未知错误',
            })
            history('/event/logsls')
        }
    }, [])//eslint-disable-line 


    return (
        <div className='topbackGroupClocl'>
            {(data && data.length > 0) && data.map((item, index) => {
                return (
                    <div index={index}>
                        <pre className='container'>{item}</pre>
                        {data.length - 1 === index && <Divider />}
                    </div>
                )
            })}
        </div>
    )
}

export default EventSlsDetail;