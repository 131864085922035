import React, { useEffect, useState } from 'react'
import { Collapse, Divider, Toast, Space, Button } from 'antd-mobile'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { orderInfoApi } from '../../../service/order'
import './index.scss'


const OrderDetail = () => {
  const [data, setData] = useState('')
  const history = useNavigate();
  const [search, setSearch] = useSearchParams()  // eslint-disable-line no-unused-vars


  const orderDetailData = (value) => {
    orderInfoApi(value).then(res => {
      Toast.clear()
      if (res.code === 400) {
        Toast.show({
          icon: 'fail',
          content: '未查询到订单信息！',
        })
        history('/order/change')
      } else if (res.code === 200 && res.data.orderInfo !== undefined && res.data.orderInfo.orderType === "changeOrder") {
        setData(res.data)
      } else if (res.code === 200 && res.data.orderBasicInfo !== undefined && res.data.orderBasicInfo.orderType === "changeShiftsOrder") {
        history('/order/shiftsDetail', { state: { data: res.data } })
      }
    }).catch(
      err => {
        console.log(err)
        Toast.show({
          icon: 'fail',
          content: '未知错误',
        })
        history('/order/change')
      }
    )
  }

  const orderReqSevice = (orderId) => {
    try {
      Toast.show({
        icon: 'loading',
        duration: 0,
        content: '加载中…',
      })
      orderDetailData({ "orderId": orderId })
    } catch (e) {
      Toast.show({
        icon: 'fail',
        content: '未知错误',
      })
      history('/order/change')
    }
  }

  const onUpClick = () => {
    orderReqSevice(data.orderInfo.associationOrderInfo.lastOrderId)
  }

  const onDownClick = () => {
    orderReqSevice(data.orderInfo.associationOrderInfo.backOrderId)
  }
  const linkEventOrder = () => {
    history(`/event/order/detail?orderId=${data.orderInfo.orderId}&idStationOrder=false`)
  }

  useEffect(() => {
    orderReqSevice(search.get('orderId'))
  }, []) //eslint-disable-line 

  return (
    <div className='topbackGroupClocl'>
      <Collapse defaultActiveKey={['1']}>
        {data && <>
          <Collapse.Panel key='1' title='订单基础信息'>
            <p>
              <span>
                类型：{data.orderInfo.orderType === 'changeOrder' ? '换电订单' : ''}
              </span>
              <Button color={'primary'} size='small' style={{ float: 'right' }} onClick={linkEventOrder} >换电事件</Button>
            </p>
            <p>云平台订单编号：{data.orderInfo.orderId}</p>
            <p>站端订单编号：{data.orderInfo.stationOrderId}</p>
            <p>订单来源：{data.orderInfo.from}</p>
            <p>订单时间：{new Date(data.orderInfo.orderTime).toLocaleString()}</p>
            <p>换电站名称：{data.orderInfo.powerStationName}</p>
            <p>换电站版本：{data.orderInfo.powerStationVersion === '1' ? '一代站' : data.orderInfo.powerStationVersion === '2' ? '二代站' : ''}</p>
            <p>下单账号：{data.orderInfo.account}</p>
            <p>车辆总里程数：{data.orderInfo.vehicleTotalMileage}</p>
            <p>上次车辆里程数：{data.orderInfo.prevVehicleTotalMileage}</p>
            <p>里程差值：{data.orderInfo.vehicleMileage}</p>
            <p>实际充电量：{data.orderInfo.chargePower}</p>
            <p>自动充电量：{data.orderInfo.autoPower}</p>
            <p>充电消耗电量（修正值）：{data.orderInfo.amendElectric}</p>
            <p>小计外接能量(kwh)：{data.orderInfo.tripOutChargeEnergy}</p>
            <p>换下电池：{data.orderInfo.downBatteryNo} &nbsp; {'' === data.orderInfo.downBatteryNo ? "" : data.assetsInfo.batteryMap.downBatteryNo ? "未入网" : "已入网"}</p>
            <p>换上电池：{data.orderInfo.upBatteryNo} &nbsp;   {'' === data.orderInfo.upBatteryNo ? "" : data.assetsInfo.batteryMap.upBatteryNo ? "未入网" : "已入网"}</p>
            <p>换电类型：{data.orderInfo.exchangeMode}</p>
            <p>订单类型：{data.orderInfo.orderCreateMode}</p>
            <p>计费方式：{data.orderInfo.paymentMode === 'quantity_electric' ? '度电' : data.orderInfo.paymentMode === 'mileage' ? '里程' : data.orderInfo.paymentMode === 'sdk_mileage' ? '三方里程' : data.orderInfo.paymentMode === 'sdk_quantity_electric' ? '三方度电' : ''}</p>
            <p>状态：{data.orderInfo.status}</p>
            <p>营销确认时间：{data.orderInfo.orderConfirmTime}</p>
            {data.orderInfo.exceptions != null &&
              <div>
                <Divider />
                {data.orderInfoexceptions.map((item, index) => {
                  return (
                    <p index={index}>     {item}</p>)
                })}
              </div>
            }


            {data.orderInfo.associationOrderInfo.lastOrderId === null && data.orderInfo.associationOrderInfo.lastOrderstatus === null ? "" :
              <div>
                <Divider />
                <p>上一笔订单号：{data.orderInfo.associationOrderInfo.lastOrderId}</p>
                <p>上一笔订单状态：{data.orderInfo.associationOrderInfo.lastOrderstatus}</p>
                <p>上一笔订单类型：{data.orderInfo.associationOrderInfo.lastOrderType === 'changeShiftsOrder' ? '交接班订单' : data.orderInfo.associationOrderInfo.lastOrderType === 'changeOrder' ? '换电订单' : ''}</p>
              </div>}

            {data.orderInfo.associationOrderInfo.backOrderId === null && data.orderInfo.associationOrderInfo.backOrderstatus === null ? "" :
              <div>
                <Divider />
                <p>下一笔订单号：{data.orderInfo.associationOrderInfo.backOrderId}</p>
                <p>下一笔订单状态：{data.orderInfo.associationOrderInfo.backOrderstatus}</p>
                <p>上一笔订单类型：{data.orderInfo.associationOrderInfo.backOrderType === 'changeShiftsOrder' ? '交接班订单' : data.orderInfo.associationOrderInfo.backOrderType === 'changeOrder' ? '换电订单' : ''}</p>

              </div>
            }
          </Collapse.Panel>
          <Collapse.Panel key='2' title='支付信息'>
            <p>总金额（元）：{data.orderPaymentInfo.totalAmount / 100}</p>
            <p>支付金额（元）：{data.orderPaymentInfo.payAmount / 100}</p>
            <p>支付时间：{null === data.orderPaymentInfo.payTime ? "" : new Date(data.orderPaymentInfo.payTime).toLocaleString()}</p>
            <Divider />
            <p>换电公式：{null === data.orderPaymentInfo.calculatedConsumeKwhFormulaCode ? "" : data.orderPaymentInfo.calculatedConsumeKwhFormulaCode}</p>
            <p>计算公式：{null === data.orderPaymentInfo.calculatedFormula ? "" : data.orderPaymentInfo.calculatedFormula}</p>
            <Divider />

            {data.orderPaymentInfo.feeFormulaVOs &&
              data.orderPaymentInfo.feeFormulaVOs.map((item, index) => {
                return (
                  <div key={index}>
                    {item.name !== null ? <p>{item.name}：{item.formula !== null ? item.formula : ""}</p> : ""}
                  </div>
                )
              })
            }

            {
              data.orderPaymentInfo.feeVOs.map((item, index) => {
                return (
                  <div key={index}>
                    <Divider />
                    {item.name !== null ? <p>名称：{item.name}</p> : ""}
                    {item.unitPrice !== null ? <p>单价（元）：{item.unitPrice / 100}</p> : ""}
                    {item.fee !== null ? <p>费用（元）：{item.fee / 100}</p> : ""}
                    {item.quantity !== null ? <p>数量：{item.quantity}</p> : ""}
                    {item.remark !== "" ? <p>备注：{item.remark}</p> : ""}
                  </div>
                )
              })
            }
          </Collapse.Panel>
          <Collapse.Panel key='3' title='资产信息'>
            <p>车牌号：{data.assetsInfo.vehicleInfo.carNo}</p>
            <p>车辆Vin码：{data.assetsInfo.vehicleInfo.vin}</p>
            <p>出行公司：{data.assetsInfo.vehicleInfo.companyName}</p>
            <p>车辆营运类型：{data.assetsInfo.vehicleInfo.operateType}</p>

            {
              !(null === data.assetsInfo.downBatteryInfo.ratedVoltage &&
                null === data.assetsInfo.downBatteryInfo.ratedCapacity &&
                null === data.assetsInfo.downBatteryInfo.ratedKwhCode) &&
              <div>
                <Divider />
                <p>电池额定电压：{data.assetsInfo.downBatteryInfo.ratedVoltage}</p>
                <p>电池额定容量：{data.assetsInfo.downBatteryInfo.ratedCapacity}</p>
                <p>电池额定度电：{data.assetsInfo.downBatteryInfo.ratedKwhCode}</p>
              </div>
            }

            {
              data.assetsInfo.downBatteryInfo.commonDataVersionRecordEntitys.length > 0 ?
                data.assetsInfo.downBatteryInfo.commonDataVersionRecordEntitys.map((item, index) => {
                  return (
                    <div key={index}>
                      <Divider />
                      <p>电池修改类型：{item.updatedType}</p>
                      <p>电池修改人：{item.updatedBy}</p>
                      <p>电池修改时间：{new Date(item.updatedTime).toLocaleString()}</p>
                    </div>
                  )
                }) : ""
            }
          </Collapse.Panel>
          {(data.orderInfo.from === "1" && data.userPhoneInfo != null) &&
            <Collapse.Panel key='4' title='设备信息'>
              <p>品牌：{data.userPhoneInfo.os !== undefined ? data.userPhoneInfo.os : ''}</p>
              <p>型号：{data.userPhoneInfo.model !== undefined ? data.userPhoneInfo.model : ''}</p>
              <p>系统版本：{data.userPhoneInfo.os_version !== undefined ? data.userPhoneInfo.os_version : ''}</p>
              <p>APP版本：{data.userPhoneInfo.app_version !== undefined ? data.userPhoneInfo.app_version : ''}</p>
              <p>运营商：{data.userPhoneInfo.carrier !== undefined ? data.userPhoneInfo.carrier : ''}</p>
              <p>网络：{data.userPhoneInfo.network_type !== undefined ? data.userPhoneInfo.network_type : ''}</p>
              <p>时间：{data.userPhoneInfo.time !== undefined ? data.userPhoneInfo.time : ''}</p>
            </Collapse.Panel>}
        </>}
      </Collapse>

      {data.orderInfo &&
        <>
          <Space justify='center' className={data.orderInfo.associationOrderInfo.lastOrderId === null || data.orderInfo.associationOrderInfo.backOrderId === null ? "orderBtnPad" : "orderBtnUPDown"} >
            {
              data.orderInfo.associationOrderInfo.lastOrderId === null && data.orderInfo.associationOrderInfo.backOrderId === null ? "" :
                <Space>
                  {data.orderInfo.associationOrderInfo.lastOrderId !== null &&
                    <Button block type='button' size="large" color='primary' className={(data.orderInfo.associationOrderInfo.lastOrderId !== null && data.orderInfo.associationOrderInfo.backOrderId !== null) && 'butSub'} onClick={onUpClick}>
                      上一笔
                    </Button>}
                  {data.orderInfo.associationOrderInfo.backOrderId !== null &&
                    <Button block size="large" type='reset' className={(data.orderInfo.associationOrderInfo.lastOrderId !== null && data.orderInfo.associationOrderInfo.backOrderId !== null) && 'butRest'} onClick={onDownClick}>
                      下一笔
                    </Button>}
                </Space>
            }
          </Space>
        </>
      }
    </div>
  )
}
export default OrderDetail