import React, { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { eventAppDeviceInfoApi } from '../../../service/tool/eventAppDevice'
import { Collapse, Toast } from 'antd-mobile'

import dayjs from 'dayjs'

const EventAppDeviceDetail = () => {
    const [data, setData] = useState([])
    const history = useNavigate();
    const [search, setSearch] = useSearchParams()  // eslint-disable-line no-unused-vars

    const EventAppDeviceDetailData = (value) => {
        eventAppDeviceInfoApi(value).then(res => {
            Toast.clear()
            if (res.code === 400) {
                Toast.show({
                    icon: 'fail',
                    content: '查询无结果',
                })
                history('/event/app/device')
            } else if (res.code === 200) {
                if (Object.keys(res.data).length !== 0) {
                    setData(res.data)
                } else {
                    Toast.show({
                        icon: 'fail',
                        content: '查询为空',
                    })
                    history('/event/app/device')
                }
            } else {
                Toast.show({
                    icon: 'fail',
                    content: '未知错误',
                })
                history('/event/app/device')
            }

        }).catch(
            err => {
                Toast.show({
                    icon: 'fail',
                    content: '未知错误',
                })
                history('/event/app/device')
            }
        )
    }

    useEffect(() => {
        try {
            Toast.show({
                icon: 'loading',
                duration: 0,
                content: '加载中…',
            })
            const timDate = '' === search.get('dataTime') ? '' : dayjs(Number(search.get('dataTime'))).format('YYYY-MM-DD HH:mm:ss')
            EventAppDeviceDetailData({ "phone": search.get('phone'), "dataTime": timDate })
        } catch (e) {
            Toast.show({
                icon: 'fail',
                content: '未知错误',
            })
            history('/event/app/device')
        }
    }, [])//eslint-disable-line 


    return (
        <div className='topbackGroupClocl'>
            {data && <>
                <h3 style={{ marginLeft: 10 }}>换电APP用户最近设备详细信息</h3>
                <Collapse defaultActiveKey={['1']}>
                    <Collapse.Panel key='1' title='设备信息记录'>
                        <p>用户：{data.distinct_id}</p>
                        <p>品牌：{data.$os}</p>
                        <p>型号：{data.$model}</p>
                        <p>系统版本：{data.$os_version}</p>
                        <p>APP版本：{data.$app_version}</p>
                        <p>运营商：{data.$carrier}</p>
                        <p>网络：{data.$network_type}</p>
                        <p>时间：{data.time}</p>
                    </Collapse.Panel>
                </Collapse>
            </>}
        </div>
    )
}

export default EventAppDeviceDetail;