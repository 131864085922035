import React, { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { casualClockApi } from '../../../service/tool/slsClockService'
import { Collapse, Toast, Divider } from 'antd-mobile'
import dayjs from 'dayjs'


const CasualClockDetail = () => {
    const [data, setData] = useState([])
    const history = useNavigate();
    const [search, setSearch] = useSearchParams()  // eslint-disable-line no-unused-vars

    const casualClockDetailData = (value) => {

        casualClockApi(value).then(res => {
            Toast.clear()
            if (res.code === 400) {
                Toast.show({
                    icon: 'fail',
                    content: res.msg,
                })
                history('/casual/clock')
            } else if (res.code === 200) {
                if (!(res.data === undefined || res.data.length === 0)) {
                    setData(res.data)
                } else {
                    Toast.show({
                        icon: 'fail',
                        content: '数据为空',
                    })
                    history('/casual/clock')
                }
            } else {
                Toast.show({
                    icon: 'fail',
                    content: '未知错误',
                })
                history('/casual/clock')
            }

        }).catch(
            err => {
                Toast.show({
                    icon: 'fail',
                    content: '未知错误',
                })
                history('/casual/clock')
            }
        )
    }

    useEffect(() => {
        try {
            Toast.show({
                icon: 'loading',
                duration: 0,
                content: '加载中…',
            })
            casualClockDetailData({
                "account": search.get('account') === "undefined" ? "" : search.get('account'),
                "startTime": search.get('startTime') === "undefined" ? "" : search.get('startTime'),
                "endTime": search.get('endTime') === "undefined" ? "" : search.get('endTime')
            })
        } catch (e) {
            Toast.show({
                icon: 'fail',
                content: '未知错误',
            })
            history('/sls/clock')
        }
    }, [])//eslint-disable-line 

    return (
        <div className='topbackGroupClocl'>
            {
                data &&
                data.map((item, index) => {
                    return (
                        <>
                            <Collapse defaultActiveKey={[index + 1]}>
                                <Collapse.Panel key={index + 1} title={item.time}>
                                    {
                                        // eslint-disable-next-line
                                        item.data && item.data.map((item1, index) => {
                                            return (
                                                <>
                                                    <p>班次所属日期：{item1.shift_begin_date}</p>
                                                    <p>数据是否有效：{item1.isAble}</p>
                                                    <p>打卡类型：{item1.clock_in_type === '1' ? "临时上班卡" : item1.clock_in_type === '2' ? "临时下班卡" : "无数据"}</p>
                                                    <p>应打卡时间：{dayjs(item1.should_clock_in_time).format('YYYY-MM-DD HH:mm:ss')}</p>
                                                    <p>实际打卡时间：{item1.clock_in_time && dayjs(item1.clock_in_time).format('YYYY-MM-DD HH:mm:ss')}</p>
                                                    <p>地址：{item1.clock_in_address}</p>
                                                    {item.data.length === index + 1 ? null : <Divider />}
                                                </>)
                                        })
                                    }
                                </Collapse.Panel>
                            </Collapse>
                        </>
                    )
                })
            }
        </div>
    )
}
export default CasualClockDetail;