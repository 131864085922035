import React from 'react'
import { Form, Button, Space, Toast } from 'antd-mobile'
import { useNavigate } from 'react-router-dom'
import StationPickSelect from '../../../components/common/StationPickSelect'


import './index.scss'

const StationChart = function () {
    const [form] = Form.useForm()
    const history = useNavigate();

    const onFinish = (value) => {

        Toast.show({
            icon: 'loading',
            duration: 0,
            content: '加载中…',
        })
        history(`/station/chart/detail?stationName=${value.stationName}`)
        Toast.clear()
    }

    return (
        <div className='topbackGroupClocl'>
            <h3 style={{ marginLeft: 10}}>换电站信息</h3>
            <Form
                form={form}
                layout='vertical'
                onFinish={onFinish}
                footer={
                    <>
                        <Space justify='center' className='controltBtnWrap' >
                            <Button block type='submit' size="large" color='primary' className='butSub'>
                                提交
                            </Button>
                            <Button block size="large" type='reset' className='butRest'>
                                重置
                            </Button>
                        </Space>
                    </>
                }

            >
                <Form.Item label='换电站名称' name='stationName' rules={[{ required: true, message: '换电站名称不能为空' }, { max: 50, message: '请输入正确的换电站名称' }]}>
                    <StationPickSelect name='请输入换电站名称' />
                </Form.Item>
            </Form>
        </div>
    )
}
export default StationChart