import React from 'react'
import { Space, Image, Card } from 'antd-mobile'
import { useNavigate } from 'react-router-dom'
import { Coupon, BankCard } from '@icon-park/react';
import srcImage from '../../static/img/station_default.png'

import './index.scss'


const CardCoupons = function () {
    const history = useNavigate();

    const couponCityData = () => {
        history('/event/package/coupon')
    }

    const couponPackagePromotionData = () => {
        history('/package/coupon/check/promotion')
    }


    return (
        <>
            <div className='orderOCouponTool'>
                <Image src={srcImage} lazy />
                <Card className='card'>
                    <Space justify='start' direction='vertical' align='center' className='imageTop package ' onClick={couponCityData}>
                        <Coupon theme="outline" size="30" fill="#4a90e2" />
                        <p className='ordePackage'>福利中心</p>
                    </Space>
                    <Space justify='start' direction='vertical' align='center' className='imageTop package ' onClick={couponPackagePromotionData}>
                        <BankCard theme="outline" size="30" fill="#4a90e2" />
                        <p className='ordePackage'>卡券核实</p>
                    </Space>
                </Card>
            </div>
        </>
    )
}
export default CardCoupons