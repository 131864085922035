import React, { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { permissionCheckApi } from '../../../service/tool/permissionCheckService'
import { Collapse, Toast } from 'antd-mobile'


const PermissionCheckDetail = () => {
    const [data, setData] = useState({})
    const history = useNavigate();
    const [search, setSearch] = useSearchParams()  // eslint-disable-line no-unused-vars

    const PermissionCheckDetailData = (value) => {

        permissionCheckApi(value).then(res => {
            Toast.clear()
            if (res.code === 400) {
                Toast.show({
                    icon: 'fail',
                    content: res.msg,
                })
                history('/permission/Check')
            } else if (res.code === 200) {
                setData(res.data)
            } else {
                Toast.show({
                    icon: 'fail',
                    content: '未知错误',
                })
                history('/permission/Check')
            }

        }).catch(
            err => {
                Toast.show({
                    icon: 'fail',
                    content: '未知错误',
                })
                history('/permission/Check')
            }
        )
    }

    useEffect(() => {
        try {
            Toast.show({
                icon: 'loading',
                duration: 0,
                content: '加载中…',
            })
            PermissionCheckDetailData({
                "type": search.get('type') === "undefined" ? "" : search.get('type'),
                "userName": search.get('userName') === "undefined" ? "" : search.get('userName'),
                "parameter": search.get('parameter') === "undefined" ? "" : search.get('parameter'),
                "account": search.get('account') === "undefined" ? "" : search.get('account'),
                "password": search.get('password') === "undefined" ? "" : search.get('password')
            })
        } catch (e) {
            Toast.show({
                icon: 'fail',
                content: '未知错误',
            })
            history('/permission/Check')
        }
    }, [])//eslint-disable-line 

    return (
        <div className='topbackGroupClocl'>
            {
                data && <>
                    <Collapse defaultActiveKey={['1']}>
                        <Collapse.Panel key='1' title='权限信息'>
                            <>
                                <p style={{ color: '#B22222' }}>查询用户是否有权限，多企业则需切换</p>
                                {data.functionName && <p>功能名称：{data.functionName}</p>}
                                {data.vehicleCompanyName && <p>车辆商家：{data.vehicleCompanyName}</p>}
                                {data.stationName && <p>换电站名称：{data.stationName}</p>}
                                {data.batteryCompanyName && <p>电池资产所属方：{data.batteryCompanyName}</p>}
                                <p>权限：{data.isAble ? '有权限' : '无权限'}</p>
                            </>
                        </Collapse.Panel>
                    </Collapse>
                </>
            }
        </div>
    )
}
export default PermissionCheckDetail;