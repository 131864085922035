import { get } from '../../../utils/request'


export function eventDeviceInfoApi(data) {
    return get('/command/event',data)
}

export function eventDeviceStationInfoApi(data) {
    return get('/command/station',data)
}

export function eventDeviceTypeInfoApi(data) {
    return get('/command/type',data)
}