import React, { useEffect, useState } from 'react'
import { Collapse, Divider, Toast, Button, Input } from 'antd-mobile'
import { useSearchParams, useNavigate } from 'react-router-dom'
import { getStationApi } from '../../../service/station/station'
import { vehicleNotLineApi, FeedbackStaionVehicleApi } from '../../../service/feedback/index'
import './index.scss'

import dayjs from 'dayjs'

const StationDetail = () => {
    const history = useNavigate();
    const [data, setData] = useState([])
    const [search, setSearch] = useSearchParams()  // eslint-disable-line no-unused-vars
    const [plateNo, setPlateNo] = useState('');

    const stationChartInfoData = (value) => {
        getStationApi(value).then(res => {
            Toast.clear()
            if (res.code === 400) {
                Toast.show({
                    icon: 'fail',
                    content: res.msg,
                })
                history('/station/chart')
            } else if (res.code === "200") {
                setData(res)
            } else {
                Toast.show({
                    icon: 'fail',
                    content: '未知错误',
                })
                history('/station/chart')
            }

        }).catch(
            err => {
                Toast.show({
                    icon: 'fail',
                    content: '未知异常',
                })
                history('/station/chart')
            }
        )
    }

    const onReportTtoEeiminate = (value) => {
        value['stationName'] = data.data.serviceInfo.stationName

        Toast.show({
            icon: 'loading',
            duration: 0,
            content: '加载中…',
        })

        vehicleNotLineApi({ "content": JSON.stringify(value), "type": "6" }).then(res => {
            Toast.clear()
            if (res.code === 400) {
                Toast.show({
                    icon: 'fail',
                    content: '反馈失败！',
                })
            } else if (res.code === 200) {
                Toast.show({
                    icon: 'success',
                    content: '反馈成功！',
                })
            } else {
                Toast.show({
                    icon: 'fail',
                    content: '未知错误',
                })
            }

        }).catch(
            err => {
                Toast.show({
                    icon: 'fail',
                    content: '未知错误',
                })
            }
        )
    }

    const onStationModal = () => {

        Toast.show({
            icon: 'loading',
            duration: 0,
            content: '加载中…',
        })
        if (plateNo === null || plateNo === '') {
            Toast.clear()
            Toast.show({
                icon: 'fail',
                content: '车牌号不能为空',
            })
        } else {
            FeedbackStaionVehicleApi({ "stationName": data.data.serviceInfo.stationName, "plateNo": plateNo, "content": "新增排队车辆" }).then(res => {
                if (res.code === 400) {
                    Toast.show({
                        icon: 'fail',
                        content: res.msg,
                    })
                } else if (res.code === 200) {
                    Toast.show({
                        icon: 'success',
                        content: '上报成功！',
                    })
                } else {
                    Toast.show({
                        icon: 'fail',
                        content: '未知错误',
                    })
                }

            }).catch(
                err => {
                    Toast.show({
                        icon: 'fail',
                        content: '未知错误',
                    })
                }
            )
        }
    }

    useEffect(() => {
        Toast.show({
            icon: 'loading',
            duration: 0,
            content: '加载中…',
        })
        stationChartInfoData({ "stationName": search.get('stationName') })
        // eslint-disable-next-line 
    }, [])

    return (
        <div className='topbackGroupClocl'>
            {
                data.data && <>
                    <h3 style={{ marginLeft: 10 }}>换电站信息</h3>
                    <Collapse defaultActiveKey={['1']}>
                        <Collapse.Panel key='1' title='换电站信息'>
                            <p>换电站名称：{data.data.serviceInfo.stationName}</p>
                            <p>营业状态：{data.data.serviceInfo.statusDesc}</p>
                            <p>排队总数：{data.data.serviceInfo.queueNum}</p>
                        </Collapse.Panel>
                        <Collapse.Panel key='2' title='电池信息'>
                            <p>最新上报时间：{'' === data.stationNewTime ? "最近15天未上报" : data.stationNewTime}</p>
                            <p>站内电池：{data.data.batterySummarize.batteryAmount}</p>
                            <p>可换电池：{data.data.batterySummarize.surplusBatteryCount}</p>
                            <p>充电中电池：{data.data.batterySummarize.chargingBatteryAmount}</p>
                            <p>故障电池：{data.data.batterySummarize.faultBatteryAmount}</p>

                        </Collapse.Panel>
                        <Collapse.Panel key='3' title='禁用电池信息'>
                            <p style={{ color: 'red' }}>最新上报时间：{'' === data.batteryNoList.batteryTime ? "没有该站禁用电池上报数据" : dayjs(data.batteryNoList.batteryTime).format('YYYY-MM-DD HH:mm:ss')}</p>
                            {data.batteryNoList && data.batteryNoList.batteryList === '' ? <p>该站没有禁用电池</p> : ''}
                            {data.batteryNoList && data.batteryNoList.batteryList && data.batteryNoList.batteryList.length > 0 && data.batteryNoList.batteryList.map((item, index) => {
                                return (
                                    <>
                                        <p key={index}>禁用日期：{item.forbidden_date}</p>
                                        <p key={index}>仓位：{item.cabin}</p>
                                        <p key={index}>电池编码：{item.battery_no}</p>
                                        <p key={index}>电池类型：{item.battery_type_name}</p>
                                        {data.batteryNoList.batteryList.length === (index + 1) ? '' : <Divider />}
                                    </>)
                            })}
                        </Collapse.Panel>
                        <Collapse.Panel key='4' title='排队信息'>

                            <div style={{ display: 'flex' }}>
                                <Input placeholder='请输入车牌号' name='plateNo' className='admInput'
                                    value={plateNo} onChange={
                                        (v) => { setPlateNo(v) }
                                    } />
                                <Button className='butFed' size='mini' color='primary' onClick={onStationModal}>
                                    上报未计入车辆
                                </Button>
                            </div>
                            {data.data.serviceInfo.queueInfoList && data.data.serviceInfo.queueInfoList.length > 0 ? <Divider /> : ""}
                            {data.data.serviceInfo.queueInfoList &&
                                data.data.serviceInfo.queueInfoList.map((item, index) => {
                                    return (
                                        <div key={index}>
                                            {<Button className='butFed' size='mini' color='primary' fill='outline' onClick={() => onReportTtoEeiminate(item)}>
                                                上报不在排队
                                            </Button>}
                                            <p>车牌号：{item.plateNo}</p>
                                            <p>车辆vin码：{item.vin}</p>
                                            <p>车辆距离：{item.distance}</p>
                                            {data.data.serviceInfo.queueInfoList.length - 1 === index ? "" : <Divider />}
                                        </div>
                                    )
                                })
                            }
                        </Collapse.Panel>
                    </Collapse></>
            }
        </div>)
}
export default StationDetail