import React, { useState } from 'react';
import { Input, DatePicker, Space } from 'antd-mobile'


function DateTimePicker(props) {
    const { name, value, onChange } = props
    const [visible, setVisible] = useState(false)
    const now = new Date()

    return (
        <Space align='center'>
            <Input
                style={{ width: '100vw' }}
                onClick={() => {
                    setVisible(true)
                }}
                value={(name === "请输入班次结束时间" || name === "请输入班次开始时间") ? value?.toLocaleDateString() : value?.toLocaleString()}
            >
            </Input>
            <DatePicker
                placeholder={name}
                visible={visible}
                onClose={() => {
                    setVisible(false)
                }}
                value={value}
                max={now}
                precision={(name === "请输入班次结束时间" || name === "请输入班次开始时间") ? "day" : "second"}
                onConfirm={
                    (value) => {
                        onChange(value)
                    }
                }
            >
            </DatePicker>
        </Space>
    )
}

export default DateTimePicker;
