import React, { useState, useEffect } from 'react'
import { Collapse, Divider, Space, Button } from 'antd-mobile'
import './index.scss'
import { useLocation, useNavigate } from 'react-router-dom'


const ShiftsOrderDetail = () => {
    const history = useNavigate();
    let location = useLocation()
    const [data, setData] = useState('')


    const orderReqSevice = (orderId) => {
        history(`/order/detail?orderId=${orderId}`)
    }

    const onUpClick = () => {
        orderReqSevice(data.orderBasicInfo.lastOrderId)
    }

    const onDownClick = () => {
        orderReqSevice(data.orderBasicInfo.backOrderId)
    }

    useEffect(() => {
        setData(location.state.data);
    }, [location.state.data])


    return (
        <div className='topbackGroupClocl'>
            <Collapse defaultActiveKey={['1']}>
                {data && <>
                    <Collapse.Panel key='1' title='订单基础信息'>
                        <p>订单类型：{data.orderBasicInfo.orderType === 'changeShiftsOrder' ? '交接班订单' : ''}</p>
                        <p>订单编号：{data.orderBasicInfo.orderNo}</p>
                        <p>订单状态：{data.orderBasicInfo.status}</p>
                        <p>订单状态描述：{data.orderBasicInfo.statusDesc}</p>
                        <p>交班时间：{data.orderBasicInfo.applyTime && new Date(data.orderBasicInfo.applyTime).toLocaleString()}</p>
                        <p>交班司机：{data.orderBasicInfo.applyDriverName}</p>
                        <p>交接人手机号：{data.orderBasicInfo.applyDriverPhone}</p>
                        <p>接班时间：{data.orderBasicInfo.receiveTime && new Date(data.orderBasicInfo.receiveTime).toLocaleString()}</p>
                        <p>接班人：{data.orderBasicInfo.receiveDriverName}</p>
                        <p>接班人手机号：{data.orderBasicInfo.receiveDriverPhone}</p>
                        {
                            (data.orderBasicInfo.rejectTime && data.orderBasicInfo.rejectReceiveReason) &&
                            <div>
                                <p>驳回时间：{new Date(data.orderBasicInfo.rejectTime).toLocaleString()}</p>
                                <p>驳回原因：{data.orderBasicInfo.rejectReceiveReason}</p>
                            </div>
                        }
                        {
                            (data.orderBasicInfo.cancelShiftsTime && data.orderBasicInfo.cancelReason) &&
                            <div>
                                <p>取消时间：{new Date(data.orderBasicInfo.cancelShiftsTime).toLocaleString()}</p>
                                <p>取消原因：{data.orderBasicInfo.cancelReason}</p>
                            </div>
                        }
                        {
                            (data.orderBasicInfo.closeTime && data.orderBasicInfo.closeReason) &&
                            <div>
                                <p>关闭时间：{new Date(data.orderBasicInfo.closeTime).toLocaleString()}</p>
                                <p>关闭原因：{data.orderBasicInfo.closeReason}</p>
                            </div>
                        }

                        {data.orderBasicInfo.lastOrderId === null && data.orderBasicInfo.lastOrderstatus === null ? "" :
                            <div>
                                <Divider />
                                <p>上一笔订单号：{data.orderBasicInfo.lastOrderId}</p>
                                <p>上一笔订单状态：{data.orderBasicInfo.lastOrderstatus}</p>
                                <p>上一笔订单类型：{data.orderBasicInfo.lastOrderType === 'changeShiftsOrder' ? '交接班订单' : data.orderBasicInfo.lastOrderType === 'changeOrder' ? '换电订单' : ''}</p>
                            </div>}

                        {data.orderBasicInfo.backOrderId === null && data.orderBasicInfo.backOrderstatus === null ? "" :
                            <div>
                                <Divider />
                                <p>下一笔订单号：{data.orderBasicInfo.backOrderId}</p>
                                <p>下一笔订单状态：{data.orderBasicInfo.backOrderstatus}</p>
                                <p>上一笔订单类型：{data.orderBasicInfo.backOrderType === 'changeShiftsOrder' ? '交接班订单' : data.orderBasicInfo.backOrderType === 'changeOrder' ? '换电订单' : ''}</p>
                            </div>
                        }
                    </Collapse.Panel>

                    <Collapse.Panel key='2' title='换电相关信息'>
                        <p>车辆vin码：{data.exchangePowerInfo.vin}</p>
                        <p>车牌号：{data.exchangePowerInfo.plateNo}</p>
                        <p>车辆型号：{data.exchangePowerInfo.carModel}</p>
                        <p>运营类型：{data.exchangePowerInfo.operateTypeDesc}</p>
                        <p>出行公司：{data.exchangePowerInfo.companyName}</p>
                        <p>计费方式：{data.exchangePowerInfo.paymentMode === "mileage" ? '里程' : data.exchangePowerInfo.paymentMode === 'quantity_electric' ? '度电' : ''}</p>
                        <p>电池编码：{data.exchangePowerInfo.batteryNo}</p>
                        <p>交班ODO里程(km)：{data.exchangePowerInfo.receiveMileage}</p>
                        <p>上次ODO里程(km)：{data.exchangePowerInfo.lastMileage}</p>
                        <p>里程差值(km)：{data.exchangePowerInfo.differenceMileage}</p>
                    </Collapse.Panel>

                    <Collapse.Panel key='3' title='支付信息'>
                        <p>订单金额（元）：{data.paymentInfo.totalAmount / 100}</p>
                        <p>里程金额（元）：{data.paymentInfo.mileageAmount / 100}</p>
                        <p>优惠金额（元）：{data.paymentInfo.promotionAmount / 100}</p>
                        <p>优惠券优惠金额（元）：{data.paymentInfo.discountAmount / 100}</p>
                        <p>支付金额（元）：{data.paymentInfo.payAmount / 100}</p>
                        <p>支付流水号：{data.paymentInfo.paymentId}</p>
                        <p>第三方支付流水号：{data.paymentInfo.outTradeNo}</p>
                        <p>支付方式：{data.paymentInfo.payChannelName}</p>
                        <p>支付方式(小类)：{data.paymentInfo.productPayWay}</p>
                        <p>支付时间：{data.paymentInfo.payTime && new Date(data.paymentInfo.payTime).toLocaleString()}</p>
                        <p>协议价结算金额(¥)（元）：{null === data.paymentInfo.contractPrice ? "" : data.paymentInfo.contractPrice / 100}</p>
                        <p>实际支付金额单位（元）：{data.paymentInfo.receiptAmount / 100}</p>
                        <p>经度（发起支付请求客户端）：{data.paymentInfo.longitude}</p>
                        <p>纬度（发起支付请求客户端的）：{data.paymentInfo.latitude}</p>

                        {
                            null !== data.paymentInfo.feeVOs &&
                            data.paymentInfo.feeVOs.map((item, index) => {
                                return (
                                    <div key={index}>
                                        <Divider />
                                        {item.name !== null ? <p>名称：{item.name}</p> : ""}
                                        {item.unitPrice !== null ? <p>单价（元）：{item.unitPrice / 100}</p> : ""}
                                        {item.fee !== null ? <p>费用（元）：{item.fee / 100}</p> : ""}
                                        {item.quantity !== null ? <p>数量：{item.quantity}</p> : ""}
                                        {item.remark !== "" ? <p>备注：{item.remark}</p> : ""}
                                    </div>
                                )
                            })
                        }
                    </Collapse.Panel>
                    {data.packageDeductDetail &&
                        <Collapse.Panel key='4' title='套餐卡抵扣'>
                            <p>订单里程（km）：{data.packageDeductDetail.orderMileage}</p>
                            <p>抵扣公里数（km）：{data.packageDeductDetail.mileage}</p>
                            <p>抵扣金额（元）：{data.packageDeductDetail.amount / 100}</p>
                            {
                                null !== data.packageDeductDetail.packageDeductRecords &&
                                data.packageDeductDetail.packageDeductRecords.map((item, index) => {
                                    return (
                                        <div key={index}>
                                            <Divider />
                                            <p>套餐名称：{item.name}</p>
                                            <p>订单换电里程（km）：{item.orderMileage}</p>
                                            <p>抵扣前剩余公里数（km）：{item.preMileage}</p>
                                            <p>抵扣公里数（km）：{item.mileage}</p>
                                            <p>抵扣后剩余公里数（km）：{item.aftMileage}</p>
                                            <p>抵扣金额：{item.amount / 100}</p>
                                            <p>抵扣时间：{item.time && new Date(item.time).toLocaleString()}</p>
                                        </div>
                                    )
                                })
                            }
                        </Collapse.Panel>}

                    {data.mileageBenefitDeductDetail &&
                        <Collapse.Panel key='5' title='权益里程抵扣'>
                            <p>抵扣公里数（km）：{data.mileageBenefitDeductDetail.mileage}</p>
                            <p>抵扣单价（元）：{data.mileageBenefitDeductDetail.unitPrice / 100}</p>
                            {null !== data.mileageBenefitDeductDetail.skuAccountDeductRecords &&
                                data.mileageBenefitDeductDetail.skuAccountDeductRecords.map((item, index) => {
                                    return (
                                        <div key={index}>
                                            <Divider />
                                            <p>名称：{item.name}</p>
                                            <p>订单换电里程（km）：{item.orderMileage}</p>
                                            <p>抵扣前剩余公里数（km）：{item.preMileage}</p>
                                            <p>抵扣公里数（km）：{item.mileage}</p>
                                            <p>抵扣后剩余公里数（km）：{item.aftMileage}</p>
                                            <p>抵扣时间：{item.time && new Date(item.time).toLocaleString()}</p>
                                        </div>
                                    )
                                })
                            }
                        </Collapse.Panel>}

                    {
                        data.couponDetailVO &&
                        <Collapse.Panel key='6' title='优惠券信息'>
                            <p>优惠券名称：{data.couponDetailVO.couponName}</p>
                            <p>优惠券金额(元)：{data.couponDetailVO.discountAmount / 100}</p>
                            <p>换电服务订单金额(元)：{data.couponDetailVO.orderAmount / 100}</p>
                            <p>优惠券类型：{data.couponDetailVO.type}</p>
                            <p>优惠券类型(满减/折扣)：{data.couponDetailVO.couponType}</p>
                            <p>固定减免随机减免：{data.couponDetailVO.voucherType}</p>
                            <p>优惠值(折扣券的时候用)：{data.couponDetailVO.voucherValue}</p>
                            <p>优惠限制(订单满多少可用)：{data.couponDetailVO.useLimit}</p>
                            <p>最多优惠额度：{data.couponDetailVO.quota}</p>
                            <p>适用业务类型：{data.couponDetailVO.businessTypes}</p>
                        </Collapse.Panel>}

                    {
                        data.batteryPriceInfo &&
                        <Collapse.Panel key='7' title='成本单价'>
                            <p>成本金额(元)：{null === data.batteryPriceInfo.costAmount ? "" : data.batteryPriceInfo.costAmount / 100}</p>
                            <p>统一价格(元)：{null === data.batteryPriceInfo.unifiedPrice ? "" : data.batteryPriceInfo.unifiedPrice / 100}</p>
                            <p>尖价格(元)：{null === data.batteryPriceInfo.spikePrice ? "" : data.batteryPriceInfo.spikePrice / 100}</p>
                            <p>峰价格(元)：{null === data.batteryPriceInfo.peakPrice ? "" : data.batteryPriceInfo.peakPrice / 100}</p>
                            <p>平价格(元)：{null === data.batteryPriceInfo.averagePrice ? "" : data.batteryPriceInfo.averagePrice / 100}</p>
                            <p>谷价格(元)：{null === data.batteryPriceInfo.valleyPrice ? "" : data.batteryPriceInfo.valleyPrice / 100}</p>
                            <p>是否启用尖峰平谷：{data.batteryPriceInfo.status === true ? "是" : data.batteryPriceInfo.status === false ? "否" : ""}</p>
                        </Collapse.Panel>}
                </>}
            </Collapse>

            {data.orderBasicInfo &&
                <>
                    <Space justify='center' className={data.orderBasicInfo.lastOrderId === null || data.orderBasicInfo.backOrderId === null ? "orderBtnPad" : "orderBtnUPDown"} >
                        {
                            data.orderBasicInfo.lastOrderId === null && data.orderBasicInfo.backOrderId === null ? "" :
                                <Space>
                                    {data.orderBasicInfo.lastOrderId !== null &&
                                        <Button block type='button' size="large" color='primary' className={(data.orderBasicInfo.lastOrderId !== null && data.orderBasicInfo.backOrderId !== null) && 'butSub'} onClick={onUpClick}>
                                            上一笔
                                        </Button>}
                                    {data.orderBasicInfo.backOrderId !== null &&
                                        <Button block size="large" type='reset' className={(data.orderBasicInfo.backOrderId !== null && data.orderBasicInfo.lastOrderId !== null) && 'butRest'} onClick={onDownClick}>
                                            下一笔
                                        </Button>}
                                </Space>
                        }
                    </Space>
                </>
            }
        </div>
    )
}
export default ShiftsOrderDetail