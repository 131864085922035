import React from 'react'
import { Form, Input, Button, Space, Toast, Selector } from 'antd-mobile'
import { useNavigate } from 'react-router-dom'

const PackageCouponPromotion = function () {
    const [form] = Form.useForm()
    const history = useNavigate();

    const onFinish = (value) => {
        Toast.show({
            icon: 'loading',
            duration: 0,
            content: '加载中…',
        })
        history(`/package/coupon/check/promotion/detail?phone=${value.phone}&promotionType=${value.promotionType[0]}&promotionName=${value.promotionName}`)
        Toast.clear()
    }

    return (
        <div className='topbackGroupClocl'>
            <h3 style={{ marginLeft: 10 }}>优惠券套餐卡核实</h3>
            <Form
                form={form}
                layout='vertical'
                onFinish={onFinish}
                footer={
                    <>
                        <Space justify='center' className='orderBtnWrap' >
                            <Button block type='submit' size="large" color='primary' className='butSub'>
                                提交
                            </Button>
                            <Button block size="large" type='reset' className='butRest'>
                                重置
                            </Button>
                        </Space>
                    </>
                }

            >
                <Form.Item label='营销类型' name="promotionType" rules={[{ required: true, message: '请选择营销类型' }]}>
                    <Selector className='selector'
                        options={[
                            {
                                label: '套餐卡',
                                value: '1',
                            },
                            {
                                label: '优惠券',
                                value: '2',
                            },
                        ]}
                    />
                </Form.Item >

                <Form.Item label='手机号' name='phone' rules={[{ required: true, message: '手机号不能为空' }, { max: 11, message: '请输入正确的手机号' }]}>
                    <Input placeholder='请输入手机号' />
                </Form.Item>
                <Form.Item label='套餐卡/优惠券名称' name='promotionName' rules={[{ required: true, message: '套餐卡/优惠券名称不能为空' }, { max: 50, message: '请输入正确的套餐卡/优惠券名称' }]}>
                    <Input placeholder='请输入订单号套餐卡/优惠券名称' />
                </Form.Item>
            </Form>
        </div>
    )
}
export default PackageCouponPromotion