import React, { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { slsClockApi } from '../../../service/tool/slsClockService'
import { Collapse, Toast, Divider } from 'antd-mobile'

import './index.scss'

const SlsClockDetail = () => {
    const [data, setData] = useState({})
    const history = useNavigate();
    const [search, setSearch] = useSearchParams()  // eslint-disable-line no-unused-vars

    const SlsClockDetailData = (value) => {

        slsClockApi(value).then(res => {
            Toast.clear()
            if (res.code === 400) {
                Toast.show({
                    icon: 'fail',
                    content: res.msg,
                })
                history('/sls/clock')
            } else if (res.code === 200) {
                setData(res.data)
            } else {
                Toast.show({
                    icon: 'fail',
                    content: '未知错误',
                })
                history('/sls/clock')
            }

        }).catch(
            err => {
                Toast.show({
                    icon: 'fail',
                    content: '未知错误',
                })
                history('/sls/clock')
            }
        )
    }

    useEffect(() => {
        try {
            Toast.show({
                icon: 'loading',
                duration: 0,
                content: '加载中…',
            })
            SlsClockDetailData({ "account": search.get('account') === "undefined" ? "" : search.get('account'), "phone": search.get('phone') === "undefined" ? "" : search.get('phone') })
        } catch (e) {
            Toast.show({
                icon: 'fail',
                content: '未知错误',
            })
            history('/sls/clock')
        }
    }, [])//eslint-disable-line 

    const isEnterClockRangeList = data.isEnterClockRangeList;
    return (
        <div className='topbackGroupClocl'>
            {
                data && <>
                    <Collapse defaultActiveKey={['1']}>
                        <Collapse.Panel key='1' title='用户信息'>
                            <>
                                <span>姓名：{data.userName}</span>
                            </>
                            {/* {
                                processChangePowerOrderInfo !== null ?
                                    (
                                        <div>
                                            <p>订单编号：{processChangePowerOrderInfo.orderNo}</p>
                                            <p>换电站名称：{processChangePowerOrderInfo.stationName}</p>
                                            <p>换电站版本：{processChangePowerOrderInfo.versionCode === '1' ? '一代站' : processChangePowerOrderInfo.versionCode === '2' ? '二代站' : ''}</p>
                                            <p>车牌号：{processChangePowerOrderInfo.plateNo}</p>
                                            <p>车辆Vin码：{processChangePowerOrderInfo.vin}</p>
                                        </div>
                                    ) : ""} */}
                        </Collapse.Panel>
                    </Collapse>
                    <Collapse defaultActiveKey={['2']}>
                        <Collapse.Panel key='1' title='定位校验信息'>
                            {
                                isEnterClockRangeList && isEnterClockRangeList.length > 0 ? isEnterClockRangeList.map((item, index) => {
                                    return (
                                        <div>
                                            <p>时间：{item.time}</p>
                                            <p>换电站名称：{item.station_name}</p>
                                            <p>换电站经度：{item.actual_longitude}</p>
                                            <p>换电站纬度：{item.actual_latitude}</p>
                                            <p>用户定位经度：{item.longitudeUser}</p>
                                            <p>用户定位纬度：{item.latitudeUser}</p>
                                            <p>是否在范围：{item.distanceIsAble === true ? "范围内" : item.distanceIsAble === false ? "范围外" : "-"}</p>
                                            <p>距离(米)：{item.distance}</p>
                                            {
                                                isEnterClockRangeList.length - 1 !== index && <Divider />
                                            }
                                        </div>
                                    )
                                }) : ''}

                        </Collapse.Panel>
                    </Collapse>
                </>
            }
        </div>
    )
}
export default SlsClockDetail;