import React from 'react'
import { Space, Image, Card } from 'antd-mobile'
import { useNavigate } from 'react-router-dom'
import { Order, Bookmark, TransactionOrder } from '@icon-park/react';
import srcImage from '../../static/img/station_default.png'

import './index.scss'


const OrderPage = function () {
    const history = useNavigate();

    const orderData = () => {
        history('/order/change')

    }
    const eventOrderData = () => {
        history('/event/order')
    }

    const rentOrderData = () => {
        history('/order/rent')
    }

    return (

        <>
            <div className='orderOCouponTool'>
                <Image src={srcImage} lazy />
                <Card className='card'>
                    <Space justify='start' direction='vertical' align='center' className='imageTop package' onClick={orderData}>
                        <Order theme="outline" size="30" fill="#4a90e2" />
                        <p className='ordePackage'>换电订单</p>
                    </Space>
                    <Space justify='start' direction='vertical' align='center' className='imageTop package' onClick={eventOrderData}>
                        <TransactionOrder theme="outline" size="30" fill="#4a90e2" />
                        <p className='ordePackage'>订单事件</p>
                    </Space>
                    <Space justify='start' direction='vertical' align='center' className='imageTop package' onClick={rentOrderData}>
                        <Bookmark theme="outline" size="30" fill="#4a90e2" />
                        <p className='ordePackage'>租金订单</p>
                    </Space>
                    </Card>
            </div>
        </>
    )
}
export default OrderPage