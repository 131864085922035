import React from 'react'
import { Space, Image, Card } from 'antd-mobile'
import { useNavigate } from 'react-router-dom'
import { Garage, Devices, Car, } from '@icon-park/react';
import srcImage from '../../static/img/station_default.png'


import './index.scss'


const Station = function () {
    const history = useNavigate();

    const stationPowerData = () => {
        history('/station/chart')

    }

    const deviceRevodeData = () => {
        history('/event/device')
    }

    const vehicleControlData = () => {
        history('/control')
    }



    return (
        <>
            <div className='orderOCouponTool'>
                <Image src={srcImage} lazy />
                <Card className='card'>
                    <Space justify='start' direction='vertical' align='center' className='imageTop package' onClick={stationPowerData}>
                        <Garage theme="outline" size="30" fill="#4a90e2" />
                        <p className='ordePackage'>换电站</p>
                    </Space>
                    <Space justify='start' direction='vertical' align='center' className='imageTop package' onClick={deviceRevodeData}>
                        <Devices theme="outline" size="30" fill="#4a90e2" />
                        <p className='ordePackage'>设备下控</p>
                    </Space>
                    <Space justify='start' direction='vertical' align='center' className='imageTop package' onClick={vehicleControlData}>
                        <Car theme="outline" size="30" fill="#4a90e2" />
                        <p className='ordePackage'>车辆下控</p>
                    </Space>
                </Card>
            </div>
        </>
    )
}
export default Station