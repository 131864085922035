import React from 'react'
import { Form, Input, Button, Space, Toast } from 'antd-mobile'
import { useNavigate } from 'react-router-dom'
import DateTimePicker from '../../../components/common/DateTimePicker'

const EventAppDevice = function () {
    const [form] = Form.useForm()
    const history = useNavigate();

    const onFinish = (value) => {

        Toast.show({
            icon: 'loading',
            duration: 0,
            content: '加载中…',
        })
        history(`/event/app/device/detail?phone=${value.phone}&dataTime=${isNaN(new Date(value.dataTime).getTime()) ? '' : new Date(value.dataTime).getTime()}`)
        Toast.clear()
    }

    return (
        <div className='topbackGroupClocl'>
            <h3 style={{ marginLeft: 10 }}>当前时间手机设备查询</h3>
            <Form
                form={form}
                layout='vertical'
                onFinish={onFinish}
                footer={
                    <>
                        <Space justify='center' className='controltBtnWrap' >
                            <Button block type='submit' size="large" color='primary' className='butSub'>
                                提交
                            </Button>
                            <Button block size="large" type='reset' className='butRest'>
                                重置
                            </Button>
                        </Space>
                    </>
                }

            >
                <Form.Item label='手机号码' name='phone' rules={[{ required: true, message: '手机号不能为空' }, { max: 11, message: '请输入正确的手机号' }, { min: 11, message: '请输入正确的手机号' }]}>
                    <Input type='number' clearable={true} />
                </Form.Item>
                <Form.Item label='时间' name='dataTime'>
                    <DateTimePicker name='请输入时间' />
                </Form.Item>
            </Form>
        </div>
    )
}
export default EventAppDevice