import React, { useEffect, useState } from 'react'
import { Collapse, Divider, Toast } from 'antd-mobile'
import { useSearchParams, useNavigate } from 'react-router-dom'
import { eventPackageOrCouponInfoApi } from '../../../service/card'

const PackageOrCouponDetail = () => {
    const history = useNavigate();
    const [data, setData] = useState('')
    const [search, setSearch] = useSearchParams()  // eslint-disable-line no-unused-vars

    const eventPackageOrCouponInfoData = (value) => {
        eventPackageOrCouponInfoApi(value).then(res => {
            Toast.clear()
            if (res.code === 400) {
                Toast.show({
                    icon: 'fail',
                    content: '查询无结果',
                })
                history('/event/package/coupon')
            } else if (res.code === 200) {
                setData(res.data)
                if (res.data.couponList.length === 0 && res.data.packageList.length === 0) {
                    Toast.show({
                        icon: 'fail',
                        content: '查询无结果',
                    })
                    history('/event/package/coupon')
                }
            } else {
                Toast.show({
                    icon: 'fail',
                    content: '未知错误',
                })
                history('/event/package/coupon')
            }

        }).catch(
            err => {
                Toast.show({
                    icon: 'fail',
                    content: '未知错误',
                })
                history('/event/package/coupon')
            }
        )
    }

    useEffect(() => {
        try {
            Toast.show({
                icon: 'loading',
                duration: 0,
                content: '加载中…',
            })
            eventPackageOrCouponInfoData({ "phone": search.get('phone') })
        } catch (e) {
            Toast.show({
                icon: 'fail',
                content: '未知错误',
            })
            history('/event/package/coupon')
        }
    }, [])//eslint-disable-line 

    return (
        <div className='topbackGroupClocl'>{
            data && <>
                <h3 style={{ marginLeft: 10 }}>用户领取套餐卡/优惠券定位信息</h3>
                <Collapse defaultActiveKey={['1']}>
                    {
                        (data.packageList && data.packageList.length > 0) &&
                        <Collapse.Panel key='1' title='套餐卡'>
                            {data.packageList.map((item, index) => {
                                return (
                                    <div key={index}>
                                        <p>时间：{item.time}</p>
                                        <p>城市：{item.cityName}</p>
                                        {
                                            data.packageList.length - 1 !== index && <Divider />
                                        }
                                    </div>
                                )
                            })}
                        </Collapse.Panel>
                    }
                    {
                        (data.couponList && data.couponList.length > 0) &&
                        <Collapse.Panel key='2' title='优惠券'>
                            {data.couponList.map((item, index) => {
                                return (
                                    <div key={index}>
                                        <p>时间：{item.time}</p>
                                        <p>城市：{item.cityName}</p>
                                        {
                                            data.couponList.length - 1 !== index && <Divider />
                                        }
                                    </div>
                                )
                            })}
                        </Collapse.Panel>
                    }
                </Collapse>
            </>}</div>
    )
}
export default PackageOrCouponDetail