import React, { useEffect, useState } from 'react'
import { Collapse, Divider, Toast } from 'antd-mobile'
import { useSearchParams, useNavigate } from 'react-router-dom'
import { eventLoginInfoApi } from '../../../service/tool/eventLoginService'

const EventLoginDetail = () => {
    const history = useNavigate();
    const [data, setData] = useState('')
    const [search, setSearch] = useSearchParams()  // eslint-disable-line no-unused-vars

    const eventLoginInfoData = (value) => {
        eventLoginInfoApi(value).then(res => {
            Toast.clear()
            if (res.code === 400) {
                Toast.show({
                    icon: 'fail',
                    content: res.msg,
                })
                history('/event/login')
            } else if (res.code === 200) {
                setData(res.data)
            } else {
                Toast.show({
                    icon: 'fail',
                    content: '未知错误',
                })
                history('/event/login')
            }

        }).catch(
            err => {
                Toast.show({
                    icon: 'fail',
                    content: '未知错误',
                })
                history('/event/login')
            }
        )
    }

    useEffect(() => {
        try {
            Toast.show({
                icon: 'loading',
                duration: 0,
                content: '加载中…',
            })
            eventLoginInfoData({ "phone": search.get('phone') })
        } catch (e) {
            Toast.show({
                icon: 'fail',
                content: '未知错误',
            })
            history('/event/loginn')
        }
    }, [])//eslint-disable-line 

    return (
        <div className='topbackGroupClocl'>{
            data && <>
                <h3 style={{ marginLeft: 10 }}>换电APP用户验证码请求参数详细信息</h3>
                <Collapse defaultActiveKey={['1']}>
                    {
                        <Collapse.Panel key='1' title='验证码登录请求参数记录'>
                            {data.map((item, index) => {
                                return (
                                    <div key={index}>
                                        <p>时间：{item.time}</p>
                                        <p>设备类型：{item.deviceType === "1" ? "Android" : item.deviceType === "2" ? "IOS" : ""}</p>
                                        <p>验证码：{item.verifyCode}</p>

                                        {
                                            data.length - 1 !== index && <Divider />
                                        }
                                    </div>
                                )
                            })}
                        </Collapse.Panel>
                    }
                </Collapse>
            </>}</div>
    )
}
export default EventLoginDetail