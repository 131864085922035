import React from 'react'
import { Form, Input, Button, Space, Toast, Selector } from 'antd-mobile'
import { useNavigate } from 'react-router-dom'

import './index.scss'

const EventOrder = function () {
    const [form] = Form.useForm()
    const history = useNavigate();

    const onFinish = (value) => {

        Toast.show({
            icon: 'loading',
            duration: 0,
            content: '加载中…',
        })
        history(`/event/order/detail?orderId=${value.orderId}&idStationOrder=${value.idStationOrder[0]}`)
        Toast.clear()
    }

    return (
        <div className='topbackGroupClocl'>
            <h3 style={{ marginLeft: 10 }}>换电订单事件查询</h3>
            <Form
                form={form}
                layout='vertical'
                onFinish={onFinish}
                footer={
                    <>
                        <Space justify='center' className='orderBtnWrap' >
                            <Button block type='submit' size="large" color='primary' className='butSub'>
                                提交
                            </Button>
                            <Button block size="large" type='reset' className='butRest'>
                                重置
                            </Button>
                        </Space>
                    </>
                }

            >
                <Form.Item label='订单方式' name="idStationOrder" rules={[{ required: true, message: '请选择订单方式' }]}>
                    <Selector className='selector'
                        options={[
                            {
                                label: '换电订单号',
                                value: 'false',
                            },
                            {
                                label: '站端订单号',
                                value: 'true',
                            },
                        ]}
                    />
                </Form.Item >

                <Form.Item label='订单号' name='orderId' rules={[{ required: true, message: '订单号不能为空' }, { max: 50, message: '请输入正确的订单号' }]}>

                    <Input placeholder='请输入订单号' />
                </Form.Item>
            </Form>
        </div>
    )
}
export default EventOrder