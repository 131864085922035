import React, { useState } from 'react';
import { ImageUploader, Toast } from 'antd-mobile';
import { iMAgeUploaderApi } from '../../../service/feedback/index'
import './index.scss'

const ImageUp = (props) => {
    const { onChange } = props
    const maxCount = 6;
    // eslint-disable-next-line
    const [fileList, setFileList] = useState([]);

    const beforeUpload = (file) => {
        if (file.size > 1024 * 1024 * 20) {
            Toast.show('请选择小于20M')
            return false
        }
        return file
    }

    const imageUpload = async (file) => {
        const res = await iMAgeUploaderApi({ "file": file })
        const httpUrl = res.data[0].url;
        return { url: httpUrl }
    }


    return (
        <ImageUploader
            upload={imageUpload}
            onChange={(fileList => {
                onChange(fileList)
            })}
            multiple
            maxCount={maxCount}
            beforeUpload={beforeUpload}
            showUpload={fileList.length < maxCount}
            onCountExceed={(exceed) => {
                Toast.show(`最多选择 ${maxCount} 张图片，你多选了 ${exceed} 张`);
            }}
        />
    );
};

export default ImageUp;
