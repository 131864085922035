import React, { useState } from 'react'
import { SearchBar, Picker, Toast } from 'antd-mobile'
import { eventDeviceTypeInfoApi } from '../../../service/station/eventDeviceService'

const DevicePickSelect = (props) => {
    const [visible, setVisible] = useState(false)
    const { name, onChange, value } = props
    const [data, setData] = useState([])

    const eventDeviceInfoTypeData = (value) => {
        eventDeviceTypeInfoApi(value).then(res => {
            if (res.code === 200) {
                const resData = res.data.map((item) => {
                    return (
                        {
                            label: item.name,
                            value: item.code
                        }
                    )
                })
                setData([resData])
            } else if (res.code === 400) {
                Toast.show({
                    icon: 'fail',
                    content: res.msg
                })
            } else {
                Toast.show({
                    icon: 'fail',
                    content: '未知错误'
                })
            }
        }).catch(err => {
            Toast.show({
                icon: 'fail',
                content: '请求异常'
            })
        })
    }

    return (
        <>
            <SearchBar
                placeholder={name}
                style={{
                    '--border-radius': '100px',
                    '--background': '#ffffff',
                    '--height': '32px',
                    '--padding-left': '12px',
                }}
                icon={null}
                value={value}
                onSearch={() => {
                    eventDeviceInfoTypeData({ "commandTypeName": value })
                    setVisible(true)
                }}
                onChange={(value) => {
                    onChange(value)
                }}
                onClear={() => {
                    onChange('')
                }}
            />

            {
                data && <Picker
                    columns={data}
                    visible={visible}

                    onClose={() => {
                        setVisible(false)
                    }}
                    value={value}
                    onConfirm={v => {
                        const label = data[0] && (data[0].find(item => item.value === v[0])?.label || '')
                        onChange(label)
                    }}
                />
            }

        </>
    )
}

export default DevicePickSelect