import React from 'react'
import { Form, Input, Button, Space, Toast, Selector } from 'antd-mobile'
import { useNavigate } from 'react-router-dom'

import './index.scss'

const PermissionCheck = function () {
    const [form] = Form.useForm()
    const history = useNavigate();

    const onFinish = (value) => {

        Toast.show({
            icon: 'loading',
            duration: 0,
            content: '加载中…',
        })
        history(`/permission/Check/detail?type=${value.type[0]}&userName=${value.userName}&parameter=${value.parameter}&account=${value.account}&password=${value.password}`)
        Toast.clear()
    }

    return (
        <div className='topbackGroupClocl'>
            <h3 style={{ marginLeft: 10 }}>权限校验查询</h3>
            <h5 style={{ marginLeft: 10, color: '#B22222' }}>查询用户是否有权限，多企业则需切换</h5>
            <Form
                form={form}
                layout='vertical'
                onFinish={onFinish}
                footer={
                    <>
                        <Space justify='center' className='orderBtnWrap' >
                            <Button block type='submit' size="large" color='primary' className='butSub'>
                                提交
                            </Button>
                            <Button block size="large" type='reset' className='butRest'>
                                重置
                            </Button>
                        </Space>
                    </>
                }

            >
                <Form.Item label='权限类型' name="type" rules={[{ required: true, message: '请选择权限方式' }]}>
                    <Selector className='selector'
                        options={[
                            {
                                label: '易指尖功能',
                                value: 'function',
                            }, {
                                label: '电池数据',
                                value: 'battery',
                            },
                            {
                                label: '换电站数据',
                                value: 'station',
                            },
                            {
                                label: '车辆数据',
                                value: 'vehicle',
                            },

                        ]}
                    />
                </Form.Item >

                <Form.Item label='用户姓名' name='userName' rules={[{ required: true, message: '用户姓名不能为空' }, { max: 50, message: '请输入正确的用户姓名' }]}>
                    <Input placeholder='请输入用户姓名' />
                </Form.Item>
                <Form.Item label='参数' name='parameter' rules={[{ required: true, message: '用户参数不能为空' }, { max: 50, message: '请输入正确的参数信息' }]}>
                    <Input placeholder='请输入参数' />
                </Form.Item>
                <Form.Item label='账号' name='account' rules={[{ required: true, message: '账号不能为空' }, { max: 50, message: '请输入正确的账号' }]}>
                    <Input placeholder='请输入账号' />
                </Form.Item>
                <Form.Item label='密码' name='password' rules={[{ required: true, message: '密码不能为空' }, { max: 50, message: '请输入正确的密码' }]}>
                    <Input placeholder='请输入密码' type='password'/>
                </Form.Item>
            </Form>
        </div>
    )
}
export default PermissionCheck