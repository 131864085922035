import React, { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { stationDistanceApi } from '../../../service/tool/stationDistanceService'
import { Collapse, Toast, Divider } from 'antd-mobile'

// import './index.scss'

const StationDistanceDetail = () => {
    const [data, setData] = useState({})
    const history = useNavigate();
    const [search, setSearch] = useSearchParams()  // eslint-disable-line no-unused-vars

    const stationDistanceDetaillData = (value) => {

        stationDistanceApi(value).then(res => {
            Toast.clear()
            if (res.code === 400) {
                Toast.show({
                    icon: 'fail',
                    content: res.msg,
                })
                history('/station/home/distance')
            } else if (res.code === 200) {
                if (res.data.length === 0) {
                    Toast.show({
                        icon: 'fail',
                        content: '数据为空',
                    })
                    history('/station/home/distance')
                }
                setData(res.data)
            } else {
                Toast.show({
                    icon: 'fail',
                    content: '未知错误',
                })
                history('/station/home/distance')
            }

        }).catch(
            err => {
                Toast.show({
                    icon: 'fail',
                    content: '未知错误',
                })
                history('/station/home/distance')
            }
        )
    }

    useEffect(() => {
        try {
            Toast.show({
                icon: 'loading',
                duration: 0,
                content: '加载中…',
            })
            stationDistanceDetaillData({
                "userName": search.get('userName') === "undefined" ? "" : search.get('userName'),
                "stationName": search.get('stationName') === "undefined" ? "" : search.get('stationName')
            })
        } catch (e) {
            Toast.show({
                icon: 'fail',
                content: '未知错误',
            })
            history('/permission/Check')
        }
    }, [])//eslint-disable-line 

    return (
        <div className='topbackGroupClocl'>
            {
                data && data.length > 0 && <>
                    <Collapse defaultActiveKey={['1']}>
                        <Collapse.Panel key='1' title={"换电站距离详情"}>

                            {
                                data.map((item, index) => {
                                    return (
                                        <>
                                            <p>请求时间：{item.time}</p>
                                            <p>换电站：{item.SysStationName}</p>
                                            <p>距离：{item.userDistance}</p>
                                            <p>目标换电站：{item.objectiveStationName}</p>
                                            <p>目标距离：{item.objectiveDistance}</p>
                                            {data.length - 1 === index ? "" : <Divider />}
                                        </>
                                    )
                                })
                            }
                        </Collapse.Panel>
                    </Collapse>
                </>
            }
        </div>
    )
}
export default StationDistanceDetail;