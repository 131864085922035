import React from 'react'
import { Form, Input, Button, Space, Toast } from 'antd-mobile'
import { useNavigate } from 'react-router-dom'

import './index.scss'

const EventPackageOrCoupon = function () {
    const [form] = Form.useForm()
    const history = useNavigate();

    const onFinish = (value) => {

        Toast.show({
            icon: 'loading',
            duration: 0,
            content: '加载中…',
        })
        history(`/event/package/coupon/detail?phone=${value.phone}`)
        Toast.clear()
    }

    return (
        <div className='topbackGroupClocl'>
            <h3 style={{ marginLeft: 10 }}>用户进入套餐卡/优惠券定位查询</h3>
            <Form
                form={form}
                layout='vertical'
                onFinish={onFinish}
                footer={
                    <>
                        <Space justify='center' className='orderBtnWrap' >
                            <Button block type='submit' size="large" color='primary' className='butSub'>
                                提交
                            </Button>
                            <Button block size="large" type='reset' className='butRest'>
                                重置
                            </Button>
                        </Space>
                    </>
                }

            >
                <Form.Item label='手机号' name='phone' rules={[{ required: true, message: '手机号不能为空' }, { max: 50, message: '请输入正确的手机号' }]}>

                    <Input placeholder='请输入手机号' />
                </Form.Item>
            </Form>
        </div>
    )
}
export default EventPackageOrCoupon