import React from 'react'
import { Collapse, Toast, Divider } from 'antd-mobile'
import { useSearchParams, useNavigate } from 'react-router-dom'
import { useState, useEffect } from 'react'
import { controloApi } from '../../../service/control'


const ControlDetail = () => {
    const [data, setData] = useState([])
    const history = useNavigate();
    const [search, setSearch] = useSearchParams() // eslint-disable-line no-unused-vars

    const controlData = (value) => {

        controloApi(value).then(res => {
            Toast.clear()
            if (res.code === 400) {
                Toast.show({
                    icon: 'fail',
                    content: '下控记录不存在！',
                })
                history('/control')
            } else if (res.code === 200) {
                setData(res.data)
            } else {
                Toast.show({
                    icon: 'fail',
                    content: '未知错误',
                })
                history('/control')
            }
        }).catch(
            err => {
                Toast.show({
                    icon: 'fail',
                    content: '未知错误',
                })
                history('/control')
            }
        )
    }
    useEffect(() => {
        try {
            Toast.show({
                icon: 'loading',
                duration: 0,
                content: '加载中…',
            })
            controlData({ 'vin': search.get('vin') })
        } catch (e) {
            Toast.show({
                icon: 'fail',
                content: '未知错误',
            })
            history('/control')
        }
    }, [])//eslint-disable-line 



    return (
        <div className='topbackGroupClocl'>
            {
            data.cmdResult &&
            <Collapse defaultActiveKey={['1']}>
                <Collapse.Panel key='1' title='车辆上报充电权限记录'>
                    <div>
                        <p>
                            充电权限：{data.charging.chargingPortStatus}
                        </p>
                        <p>
                            SOE：{data.charging.soe}
                        </p>
                        <p>
                            里程：{data.charging.mileage}
                        </p>
                        <p>
                            时间：{data.charging.time}
                        </p>
                    </div>

                </Collapse.Panel>
                <Collapse.Panel key='2' title='车辆上报充电权限状态记录'>
                    {data.cmdResult.map((item, index) => {
                        return (
                            <div key={index}>
                                <p>时间：{item.time}</p>
                                <p>状态：{
                                    item.status ? item.status === '1' ? '异常' :
                                        item.status === '2' ? '允许充电' :
                                            item.status === '3' ? '不允许充电' :
                                                item.status === '4' ? '挂起（车辆充电中）' :
                                                    item.status === '254' ? '异常' : '未知' : ''
                                }</p>
                                {data.length - 1 === index ? "" : <Divider />}
                            </div>
                        )
                    }
                    )}
                </Collapse.Panel>

                {data.cmd.length > 0 &&
                    <Collapse.Panel key='3' title='下控指令记录'>
                        {
                            data.cmd.map((item, index) => {
                                return (
                                    <div>
                                        <p>下控时间：{item.time}</p>
                                        <p>下控指令：{item.cmdMsg}</p>
                                        {data.cmd.length - 1 === index ? "" : <Divider />}
                                    </div>

                                )
                            })
                        }
                    </Collapse.Panel>}
            </Collapse>}

        </div>
    )
}
export default ControlDetail