import React, { useEffect, useState } from 'react'
import { Collapse, Toast } from 'antd-mobile'
import { useSearchParams, useNavigate } from 'react-router-dom'
import { packageOrCouponPromotionInfoApi } from '../../../service/card/'

const PackageCouponPromotionDetail = () => {
    const history = useNavigate();
    const [data, setData] = useState('')
    const [search, setSearch] = useSearchParams()  // eslint-disable-line no-unused-vars

    const packageOrCouponInfoData = (value) => {
        packageOrCouponPromotionInfoApi(value).then(res => {
            Toast.clear()
            if (res.code === 400) {
                Toast.show({
                    icon: 'fail',
                    content: res.msg,
                })
                history('/package/coupon/check/promotion')
            } else if (res.code === 200) {
                setData(res.data)
                if (res.data.flag === true) {
                    Toast.show({
                        icon: 'success',
                        content: res.msg,
                    })
                    history('/package/coupon/check/promotion')
                }
            } else {
                Toast.show({
                    icon: 'fail',
                    content: '未知错误',
                })
                history('/package/coupon/check/promotion')
            }

        }).catch(
            err => {
                Toast.show({
                    icon: 'fail',
                    content: '未知错误',
                })
                history('/package/coupon/check/promotion')
            }
        )
    }

    useEffect(() => {
        try {
            Toast.show({
                icon: 'loading',
                duration: 0,
                content: '加载中…',
            })
            packageOrCouponInfoData({ "phone": search.get('phone'), "promotionType": search.get('promotionType'), "promotionName": search.get('promotionName') })
        } catch (e) {
            Toast.show({
                icon: 'fail',
                content: '未知错误',
            })
            history('/package/coupon/check/promotion')
        }
    }, [])//eslint-disable-line 

    return (
        <div className='topbackGroupClocl'>{
            data && <>
                <h3 style={{ marginLeft: 10 }}>用户当前优惠券/套餐卡不可使用信息</h3>
                <Collapse defaultActiveKey={['1']}>
                    {
                        (('' === data.fee) || data.fee) &&
                        <Collapse.Panel key='1' title='套餐卡'>
                            <p>车型：{data.carType}</p>
                            <p>商家：{data.travelCompany}</p>
                            <p>定位城市：{data.city && data.city.cityName}</p>
                            <p>匹配城市：{data.city && data.city.packageCouponCity}</p>
                            <p>计费：{data.fee}</p>
                        </Collapse.Panel>
                    }

                    {
                        undefined === data.fee &&
                        <Collapse.Panel key='2' title='优惠券'>
                            <p>车型：{data.carType}</p>
                            <p>车辆营运类型：{data.operationType}</p>
                            <p>商家：{data.travelCompany}</p>
                            <p>定位城市{data.city &&  data.city.cityName}</p>
                            <p>匹配城市：{data.city &&  data.city.counponCityName}</p>
                        </Collapse.Panel>
                    }
                </Collapse>
            </>}</div>
    )
}
export default PackageCouponPromotionDetail